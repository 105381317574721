.component__leadInfoModal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.438);
    cursor: default;

    &__wrapper {
        background: #11182F;
        width: 95%;
        height: 95%;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid rgb(53, 43, 201);

        @media screen and (max-width: 1500px) {
            overflow-y: scroll;
            overflow-x: scroll;
        }

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 1500px) {
                width: 2000px;
            }

            @media screen and (max-width: 700px) {
                width: 100%;
            }

            &>h1 {
                font-size: 20px;
                color: #ffffff;
                font-weight: 400;
                font-family: Figtree;

                @media screen and (max-width: 700px) {
                    font-size: 17px;
                }
            }

            &>img {
                width: 24px;
                border: 1px solid #ffffff;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        &__body {
            display: grid;
            grid-template-columns: 2.2fr 1fr;
            gap: 20px;
            padding-top: 10px;
            width: 100%;
            max-height: 850px;

            @media screen and (max-width: 1500px) {
                width: 2000px;
            }

            &__left {
                display: grid;
                grid-template-rows: 1fr 1fr;
                gap: 20px;
                width: 100%;
                max-height: 850px;

                &__top {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 20px;
                    width: 100%;
                    max-height: 375px;

                    &__info {
                        overflow: hidden;
                        background: #101527;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 5px;
                        padding: 10px;
                        border-radius: 5px;
                        width: 100%;
                        border: 1px solid rgb(53, 43, 201);
                        overflow-y: scroll;

                        &>p {
                            color: #ffffff;
                            font-family: Figtree;
                            font-size: 15px;
                        }

                        &__buttons {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            padding-top: 5px;
                            gap: 10px;

                            &__deleteLead {
                                background: rgb(53, 43, 201);
                                cursor: pointer;
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                height: 25px;
                                border-radius: 5px;
                                color: #ffffff;
                                border: 2px solid rgb(42, 34, 153);
                                transition: 400ms;

                                &:hover {
                                    box-shadow: 0 0 8px rgb(42, 34, 153);
                                }

                                &>p {
                                    font-size: 15px;
                                    font-family: Figtree;
                                }
                            }

                            &__responsible {
                                background: rgb(53, 43, 201);
                                cursor: pointer;
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                height: 25px;
                                border-radius: 5px;
                                color: #ffffff;
                                border: 2px solid rgb(42, 34, 153);
                                transition: 400ms;

                                &:hover {
                                    box-shadow: 0 0 8px rgb(42, 34, 153);
                                }

                                &>p {
                                    font-size: 15px;
                                    font-family: Figtree;
                                }
                            }
                        }
                    }

                    &__comments {
                        overflow: hidden;
                        background: #101527;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 5px;
                        padding: 10px;
                        border-radius: 5px;
                        width: 100%;
                        border: 1px solid rgb(53, 43, 201);

                        &__header {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            align-items: flex-end;
                            width: 100%;
                            gap: 5px;

                            &>textarea {
                                width: 100%;
                                padding: 10px;
                                outline: none;
                                border: none;
                                border-radius: 5px 5px 0px 0px;
                                font-size: 14px;
                                font-family: Figtree;
                                background: transparent;
                                border: 1px solid rgb(53, 43, 201);
                                color: #ffffff;

                                &::placeholder {
                                    color: #ffffff;
                                }
                            }

                            &__button {
                                background: rgb(53, 43, 201);
                                cursor: pointer;
                                width: 20%;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                height: 25px;
                                border-radius: 5px;
                                color: #ffffff;
                                border: 2px solid rgb(42, 34, 153);
                                transition: 400ms;

                                &:hover {
                                    box-shadow: 0 0 8px rgb(42, 34, 153);
                                }

                                &>p {
                                    font-size: 15px;
                                    font-family: Figtree;
                                }
                            }
                        }

                        &__list {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap: 5px;
                            overflow-y: scroll;
                            width: 100%;
                            max-height: 100%;
                            height: 100%;
                            padding-top: 10px;

                            &__comment {
                                width: 99%;
                                max-width: 99%;
                                min-width: 99%;
                                border: 1px solid rgb(53, 43, 201);
                                padding: 5px;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                gap: 3px;
                                border-radius: 5px;

                                &>p {
                                    color: #ffffff;
                                    font-family: Figtree;
                                    font-size: 15px;
                                }

                                &>strong {
                                    color: #ffffff;
                                    font-family: Figtree;
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }

                &__bottom {
                    max-height: 375px;
                    width: 100%;
                    display: grid;
                    grid-template-rows: 7fr 1fr;
                    gap: 20px;

                    &__upper {
                        width: 100%;
                        display: grid;
                        grid-template-columns: 2fr 1fr;
                        gap: 20px;

                        &__sell {
                            width: 100%;
                            background: #101527;
                            border: 1px solid rgb(53, 43, 201);
                            border-radius: 5px;
                            padding: 10px;
                            overflow: hidden;
                            max-height: 375px;
                            min-height: 375px;

                            &>p {
                                font-family: Figtree;
                                font-size: 14px;
                                color: #101527;
                                font-style: italic;
                                background: #FF9507;
                                padding: 3px;
                                border-radius: 5px;
                            }

                            &__editPriceBtn {
                                background: rgb(53, 43, 201);
                                cursor: pointer;
                                width: 20%;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                height: 25px;
                                border-radius: 5px;
                                color: #ffffff;
                                border: 2px solid rgb(42, 34, 153);
                                transition: 400ms;
                                margin-top: 10px;

                                &:hover {
                                    box-shadow: 0 0 8px rgb(42, 34, 153);
                                }

                                &>p {
                                    font-size: 15px;
                                    font-family: Figtree;
                                }
                            }

                            &__container {
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 10px;
                                padding-top: 10px;

                                &__field {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    gap: 3px;
                                    position: relative;

                                    &>label {
                                        color: #ffffff;
                                        font-family: Figtree;
                                        font-size: 14px;
                                    }

                                    &>input {
                                        width: 100%;
                                        outline: none;
                                        border: none;
                                        height: 25px;
                                        padding-left: 5px;
                                        font-size: 15px;
                                        color: #ffffff;
                                        font-family: Figtree;
                                        background: transparent;
                                        border-bottom: 2px solid rgb(53, 43, 201);
                                    }

                                    &__selected{
                                        width: 100%;
                                        outline: none;
                                        border: none;
                                        height: 25px;
                                        padding-left: 5px;
                                        font-size: 15px;
                                        color: #ffffff;
                                        font-family: Figtree;
                                        background: transparent;
                                        border-bottom: 2px solid rgb(53, 43, 201);
                                    }

                                    &__dropdown{
                                        max-height: 0px;
                                        position: absolute;
                                        top: 45px;
                                        background: rgb(53, 43, 201);
                                        width: 100%;
                                        border-radius: 0 0 5px 5px;
                                        z-index: 99999999;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-start;
                                        align-items: flex-start;
                                        gap: 5px;
                                        overflow: hidden;
                                        transition: all 400ms ease-in-out;

                                        &>p{
                                            cursor: pointer;
                                            background: rgb(42, 34, 153);
                                            padding: 5px;
                                            font-size: 13px;
                                            transition: 400ms;
                                            color: #FFFFFF;
                                            width: 100%;

                                            &:hover{
                                                background: rgb(40, 29, 196);
                                            }
                                        }

                                        &__active{
                                            max-height: 150px;
                                            padding: 5px;
                                            overflow-y: scroll;
                                        }
                                    }
                                }
                            }

                            &__bottom {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                                gap: 20px;
                                padding-top: 5px;

                                &__error {
                                    font-size: 17px;
                                    color: rgb(184, 61, 61);
                                }

                                &__button {
                                    background: rgb(53, 43, 201);
                                    cursor: pointer;
                                    width: 20%;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    height: 25px;
                                    border-radius: 5px;
                                    color: #ffffff;
                                    border: 2px solid rgb(42, 34, 153);
                                    transition: 400ms;
                                    margin-top: 10px;
                                    margin-left: auto;
                                    margin-right: 0;

                                    &:hover {
                                        box-shadow: 0 0 8px rgb(42, 34, 153);
                                    }

                                    &>p {
                                        font-size: 15px;
                                        font-family: Figtree;
                                    }
                                }
                            }
                        }

                        &__statuses {
                            background: #101527;
                            border: 1px solid rgb(53, 43, 201);
                            border-radius: 5px;
                            padding: 10px;
                            max-height: 375px;
                            min-height: 375px;
                            display: grid;
                            grid-template-rows: auto auto auto auto;
                            gap: 10px;

                            &__current {
                                width: 100%;
                                height: fit-content;
                                padding: 3px;
                                border-radius: 5px;
                                color: #ffffff;

                                &>p {
                                    font-family: Figtree;
                                    font-size: 15px;
                                }
                            }

                            &__list {
                                margin-top: 10px;
                                overflow-y: scroll;
                                display: grid;
                                grid-template-columns: 1fr;
                                gap: 10px;

                                &__item {
                                    height: fit-content;
                                    padding: 3px;
                                    width: 98%;
                                    border-bottom: 2px solid rgb(53, 43, 201);
                                    cursor: pointer;

                                    &>p {
                                        font-size: 15px;
                                        font-family: Figtree;
                                        color: #ffffff;
                                    }
                                }

                                &__button {
                                    margin-top: 10px;
                                    background: rgb(53, 43, 201);
                                    cursor: pointer;
                                    width: fit-content;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    height: 25px;
                                    border-radius: 5px;
                                    color: #ffffff;
                                    border: 2px solid rgb(42, 34, 153);
                                    transition: 400ms;
                                    margin-top: 10px;
                                    margin-left: auto;
                                    margin-right: 0;

                                    &:hover {
                                        box-shadow: 0 0 8px rgb(42, 34, 153);
                                    }

                                    &>p {
                                        font-size: 15px;
                                        font-family: Figtree;
                                    }
                                }
                            }
                        }
                    }

                    &__buttons {
                        background: #101527;
                        border: 1px solid rgb(53, 43, 201);
                        border-radius: 5px;
                        padding: 10px;
                        display: grid;
                        grid-template-columns: 1fr;
                        gap: 10px;

                        &__field {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            gap: 10px;

                            &>p {
                                color: #ffffff;
                                font-family: Figtree;
                                font-size: 15px;
                            }

                            &>input[type='date'] {
                                background: transparent;
                                border: none;
                                color: #ffffff;
                                outline: none;

                                &::-webkit-calendar-picker-indicator {
                                    background: transparent;
                                }
                            }

                            &__btn {
                                background: rgb(53, 43, 201);
                                cursor: pointer;
                                width: fit-content;
                                padding-left: 10px;
                                padding-right: 10px;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                height: 20px;
                                border-radius: 5px;
                                color: #ffffff;
                                border: 2px solid rgb(42, 34, 153);
                                transition: 400ms;

                                &:hover {
                                    box-shadow: 0 0 8px rgb(42, 34, 153);
                                }

                                &>p {
                                    font-size: 15px;
                                    font-family: Figtree;
                                }
                            }
                        }
                    }
                }
            }

            &__right {
                max-height: 850px;
                width: 100%;
                background: #101527;
                border: 1px solid rgb(53, 43, 201);
                border-radius: 5px;
                padding: 10px;
            }
        }
    }

    &__agentList {
        background: rgba(0, 0, 0, 0.411);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;

        &__wrapper {
            width: 300px;
            max-width: 300px;
            background: #11182F;
            height: 400px;
            max-height: 400px;
            padding: 20px;
            border-radius: 5px;
            border: 1px solid rgb(53, 43, 201);

            &__header {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                &>p {
                    font-size: 18px;
                    font-family: Figtree;
                }

                &>img {
                    width: 20px;
                    border: 1px solid #ffffff;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }

            &__list {
                background: #101527;
                border: 1px solid rgb(53, 43, 201);
                margin-top: 20px;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                border-radius: 5px;
                max-height: 290px;
                min-height: 290px;
                overflow-y: scroll;

                &>p {
                    font-size: 15px;
                    font-family: Figtree;
                    border-bottom: 1px solid rgb(53, 43, 201);
                    width: 98%;
                    padding-bottom: 4px;
                    cursor: pointer;
                }
            }
        }
    }
}

.component__editPrice {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.411);
    cursor: default;

    &__wrapper {
        width: 300px;
        max-width: 300px;
        background: #11182F;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid rgb(53, 43, 201);

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &>h1 {
                font-size: 17px;
                color: #ffffff;
                font-family: Figtree;
                font-weight: 400;
            }

            &>img {
                width: 24px;
                border: 1px solid #ffffff;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 5px;
            background: #101527;
            margin-top: 20px;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid rgb(53, 43, 201);

            &>p {
                font-size: 15px;
                color: #ffffff;
                font-family: Figtree;
                font-weight: 400;
            }

            &>input {
                width: 100%;
                background: transparent;
                outline: none;
                border: none;
                border-bottom: 2px solid rgb(53, 43, 201);
                height: 25px;
                color: #ffffff;
            }

            &__button {
                margin-top: 10px;
                background: rgb(53, 43, 201);
                cursor: pointer;
                width: fit-content;
                padding-left: 10px;
                padding-right: 10px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 25px;
                border-radius: 5px;
                color: #ffffff;
                border: 2px solid rgb(42, 34, 153);
                transition: 400ms;
                margin-top: 10px;
                margin-left: auto;
                margin-right: 0;

                &:hover {
                    box-shadow: 0 0 8px rgb(42, 34, 153);
                }

                &>p {
                    font-size: 15px;
                    color: #ffffff;
                    font-family: Figtree;
                    font-weight: 400;
                }
            }
        }
    }
}

.component__newShipmentModal{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.411);
    cursor: default;

    &__wrapper{
        width: 800px;
        height: fit-content;
        background: #11182F;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid rgb(53, 43, 201);

        &>img{
            width: 24px;
            border: 1px solid #FFFFFF;
            border-radius: 50%;
            cursor: pointer;
            margin-left: auto;
            margin-right: 0;
            display: block;
        }

        &__form{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
            padding-top: 10px;

            &__field {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 3px;
                position: relative;

                &>label {
                    color: #ffffff;
                    font-family: Figtree;
                    font-size: 15px;
                }

                &>input {
                    width: 100%;
                    outline: none;
                    border: none;
                    height: 25px;
                    padding-left: 5px;
                    font-size: 15px;
                    color: #ffffff;
                    font-family: Figtree;
                    background: transparent;
                    border-bottom: 2px solid rgb(53, 43, 201);
                }

                &__selected{
                    width: 100%;
                    outline: none;
                    border: none;
                    height: 25px;
                    padding-left: 5px;
                    font-size: 15px;
                    color: #ffffff;
                    font-family: Figtree;
                    background: transparent;
                    border-bottom: 2px solid rgb(53, 43, 201);
                }

                &__dropdown{
                    max-height: 0px;
                    position: absolute;
                    top: 45px;
                    background: rgb(53, 43, 201);
                    width: 100%;
                    border-radius: 0 0 5px 5px;
                    z-index: 99999999;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                    overflow: hidden;
                    transition: all 400ms ease-in-out;

                    &>p{
                        cursor: pointer;
                        background: rgb(42, 34, 153);
                        padding: 5px;
                        font-size: 13px;
                        transition: 400ms;
                        color: #FFFFFF;
                        width: 100%;

                        &:hover{
                            background: rgb(40, 29, 196);
                        }
                    }

                    &__active{
                        max-height: 150px;
                        padding: 5px;
                        overflow-y: scroll;
                    }
                }
            }
        }

        &__actions{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            padding-top: 5px;

            &__error {
                font-size: 17px;
                color: rgb(184, 61, 61);
            }

            &__button {
                background: rgb(53, 43, 201);
                cursor: pointer;
                width: 20%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 25px;
                border-radius: 5px;
                color: #ffffff;
                border: 2px solid rgb(42, 34, 153);
                transition: 400ms;
                margin-top: 10px;
                margin-left: auto;
                margin-right: 0;

                &:hover {
                    box-shadow: 0 0 8px rgb(42, 34, 153);
                }

                &>p {
                    font-size: 15px;
                    font-family: Figtree;
                }
            }
        }
    }
}