*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Figtree";
}

.route__contentWrapper{
  height: 100vh;
  overflow-x: hidden;
}

.route__contentWrapper__body{
  display: grid;
  grid-template-columns: 230px auto;
  min-height: 100vh;
}

.route__contentWrapper__body__routes{
  background: #080B16;
  min-height: 100%;
  height: 100%;
}

.route__contentWrapper__body__routes__home{
  background: #080B16;
  height: 100vh;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root{
  min-height: 100vh;
  overflow: hidden;
  background: #080B16;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(72, 63, 215);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(56, 47, 180);
}