.route__offers {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;

    &__container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 10px;
        padding: 20px;

        &__offer {
            width: 100%;
            background: #192343;
            padding: 5px;
            cursor: pointer;
            border-radius: 10px;

            &>img {
                width: 100%;
                height: 270px;
            }

            &__headline {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-bottom: 5px;

                &>p{
                    font-family: Figtree;
                    color: #FFFFFF;
                    font-weight: 400;
                    font-size: 15px;
                }

                &>strong {
                    font-size: 18px;
                    font-family: Figtree;
                    color: #FFFFFF;
                    font-weight: 500;
                }
            }

            &>span {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                font-family: Figtree;
                color: #FFFFFF;
                font-weight: 400;
                font-size: 15px;

                &>img {
                    width: 35px;
                }
            }
        }
    }
}

.route__offers__header {
    width: 100%;
    padding: 20px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 725px){
        padding: 10px;
    }

    &__dropdown {
        position: relative;
        @media screen and (max-width: 725px){
            width: 100%;
        }

        &__body {
            max-height: 0px;
            overflow: hidden;
            position: absolute;
            top: 31px;
            right: 0;
            background: #192343;
            transition: all 0.3s ease;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            width: 100%;
            z-index: 9999;

            &--active {
                max-height: 1000px;
                padding: 20px;
                border-radius: 0 0 5px 5px;
                border-right: 1px solid #263565;
                border-left: 1px solid #263565;
                border-bottom: 1px solid #263565;
            }

            &>p {
                cursor: pointer;
                font-size: 14px;
                width: 100%;
                padding: 5px;
                border-bottom: 1px solid #263565;
                color: #FFFFFF;
                font-family: Figtree;

                &:hover {
                    background: #263565;
                    color: rgb(243, 243, 243);
                    transition: 400ms;
                }
            }
        }

        &__head {
            width: 180px;
            background: #192343;
            padding: 5px;
            border-radius: 5px;
            height: 31px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border: 1px solid #263565;

            @media screen and (max-width: 1290px){
                width: 150px;
            }
            @media screen and (max-width: 725px){
                width: 100%;
            }

            &--active {
                border-radius: 5px 5px 0 0;
            }

            &>p {
                font-size: 14px;
                color: #FFFFFF;
                font-family: Figtree;
            }
        }
    }
}


.modal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.466);

    &__wrapper {
        width: 500px;
        height: 280px;
        background: #11182F;
        cursor: default;
        border-radius: 5px;
        border: 1px solid rgb(53, 43, 201);
        padding: 20px;

        &__head {
            background: #11182F;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 10px;

            &>h1 {
                color: #F3F3F3;
                font-size: 18px;
                font-weight: 500;
                font-family: Figtree;
            }

            &>img {
                cursor: pointer;
                width: 24px;
                height: 24px;
                border: 1px solid #FFFFFF;
                border-radius: 50%;
            }
        }

        &__details {
            padding: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 100%;

            &>span {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                color: #FFFFFF;
                font-size: 15px;
                width: 100%;
                margin-bottom: 5px;
                font-family: Figtree;
            }

            &>button {
                border: none;
                cursor: pointer;
                height: 30px;
                font-size: 16px;
                font-weight: 500;
                color: rgb(19, 33, 60);
                width: 300px;
            }

        }

        &__apiKey {
            padding: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 100%;
            gap: 10px;

            &>p {
                font-size: 15px;
                color: #FFFFFF;
                font-family: Figtree;
            }

            &>input {
                outline: none;
                width: 100%;
                height: 30px;
                border: none;
                background: transparent;
                padding-left: 5px;
                font-size: 15px;
                border-bottom: 2px solid rgb(53, 43, 201);
                color: #FFFFFF;
            }

            &__btns {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 5px;

                &>button {
                    background: rgb(53, 43, 201);
                    cursor: pointer;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    border-radius: 5px;
                    color: #ffffff;
                    border: 2px solid rgb(42, 34, 153);
                    transition: 400ms;
    
                    &:hover {
                        box-shadow: 0 0 8px rgb(42, 34, 153);
                    }
    
                    &>p {
                        font-size: 15px;
                        font-family: Figtree;
                    }
                }

                &>input {
                    color: #FFFFFF;
                }
            }

            &__landers {
                width: 100%;
                overflow-y: scroll;
                height: 50%;
                max-height: 50%;
                background: transparent;
                padding: 5px;
                border: 1px solid rgb(53, 43, 201);
                border-radius: 5px;

                &>span {
                    border-bottom: 2px solid rgb(53, 43, 201);
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    padding: 3px;

                    &>a {
                        font-size: 15px;
                        text-decoration: none;
                        color: #ffffff;
                        font-family: Figtree;
                    }

                    &>img{
                        width: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1703px){
    .route__offers__container{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .route__offers__container__offer > img{
        height: 300px;
    }
}

@media screen and (max-width: 1155px){
    .route__offers__container{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .route__offers__container__offer > img{
        height: 320px;
    }
}

@media screen and (max-width: 892px){
    .route__offers__container{
        padding: 10px;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .route__offers__container__offer > img{
        height: 300px;
    }
}

@media screen and (max-width: 791px){
    .route__offers__container{
        padding: 10px;
        grid-template-columns: 1fr 1fr;
    }
    .route__offers__container__offer > img{
        height: 320px;
    }
}

@media screen and (max-width: 524px){
    .route__offers__container{
        padding: 10px;
        grid-template-columns: 1fr;
    }
    .route__offers__container__offer > img{
        height: 450px;
    }
    .modal__wrapper{
        width: 95%;
    }
}

@media screen and (max-width: 450px){
    .route__offers__container{
        padding: 10px;
        grid-template-columns: 1fr;
    }
    .route__offers__container__offer > img{
        height: 370px;
    }
    .route__offers__header__filters > p{
        font-size: 18px;
    }
    .modal__wrapper__apiKey__btns > button{
        font-size: 15px;
    }
    .modal__wrapper__apiKey__btns > input{
        width: 100%;
    }
    .modal__wrapper__apiKey__landers > span > a{
        font-size: 15px;
    }
    .modal__wrapper__apiKey > p{
        font-size: 15px;
    }
}

@media screen and (max-width: 380px){
    .route__offers__container{
        padding: 10px;
        grid-template-columns: 1fr;
    }
    .route__offers__container__offer > img{
        height: 350px;
    }
}

@media screen and (max-width: 340px){
    .route__offers__container{
        padding: 10px;
        grid-template-columns: 1fr;
    }
    .route__offers__container__offer > img{
        height: 310px;
    }
}