.component__extensionsModal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.438);
    cursor: default;

    &__wrapper {
        width: 1000px;
        height: 500px;
        max-height: 500px;
        background: #11182F;
        border-radius: 5px;
        padding: 20px;
        border: 1px solid rgb(53, 43, 201);

        @media screen and (max-width: 1040px){
            width: 95%;
        }

        @media screen and (max-width: 500px){
            height: 95%;
            max-height: 95%;
        }

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &>p {
                font-family: Figtree;
                font-size: 18px;
            }

            &>img {
                width: 24px;
                border: 1px solid #ffffff;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        &__body {
            display: grid;
            grid-template-columns: 1fr 350px;
            gap: 20px;
            padding-top: 10px;

            @media screen and (max-width: 1040px){
                grid-template-columns: 1fr;
                overflow-y: scroll;
                max-height: 400px;
            }

            @media screen and (max-width: 500px){
                height: 95%;
                max-height: 95%;
            }

            &__box {
                background: #101527;
                width: 100%;
                height: 420px;
                max-height: 420px;
                border-radius: 5px;
                border: 1px solid rgb(53, 43, 201);
                padding: 10px;

                @media screen and (max-width: 1040px){
                    width: 95%;
                }

                &>p {
                    font-family: Figtree;
                    font-size: 15px;
                    border-bottom: 2px solid rgb(53, 43, 201);
                    width: 100%;
                    text-align: end;
                    padding-bottom: 3px;
                    color: #ffffff;
                }

                &__list {
                    height: 350px;
                    max-height: 350px;
                    width: 100%;
                    padding-top: 10px;
                    overflow-y: scroll;

                    &__lead {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 10px;
                        align-items: center;
                        padding: 5px;
                        border: 1px solid rgb(53, 43, 201);
                        width: 99%;

                        &__info {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            cursor: pointer;

                            &>p {
                                font-family: Figtree;
                                color: #ffffff;
                                font-size: 13px;
                            }
                        }

                        &__actions{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            gap: 10px;

                            &__checkboxes{
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                gap: 5px;
                                max-width: 100px;
                                min-width: 100px;

                                &__item{
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    align-items: center;
                                    gap: 5px;
                                    width: 100%;
                                    font-size: 13px;

                                    &:last-child{
                                        border-top: 2px solid #ffffff;
                                        padding-top: 5px;
                                    }
                                }
                            }

                            &>img{
                                width: 24px;
                                cursor: pointer;
                                margin-left: auto;
                                margin-right: 0;
                            }

                            &__btn{
                                background: rgb(53, 43, 201);
                                cursor: pointer;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                height: 25px;
                                border-radius: 5px;
                                color: #ffffff;
                                border: 2px solid rgb(42, 34, 153);
                                transition: 400ms;
                                width: 100px;

                                &:hover{
                                    box-shadow: 0 0 8px rgb(42, 34, 153);
                                }

                                &>p{
                                    font-size: 13px;
                                    font-family: Figtree;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}