.component__logViewer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.329);

    &__wrapper {
        width: 1100px;
        max-height: 700px;
        min-height: 700px;
        background: #11182F;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid rgb(53, 43, 201);

        @media screen and (max-width: 1127px) {
            width: 98%;
        }

        @media screen and (max-width: 550px) {
            padding: 10px;
            max-height: 500px;
            min-height: 500px;
        }

        &__header {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            @media screen and (max-width: 550px) {
                padding-bottom: 10px;
            }

            &>h3 {
                font-size: 18px;
                font-family: Figtree;
                color: #FFFFFF;
                font-weight: 500;

                @media screen and (max-width: 1127px) {
                    font-size: 16px;
                }

                @media screen and (max-width: 474px) {
                    font-size: 15px;
                    text-align: center;
                }
            }
        }

        &__screen {
            max-height: calc(100% - 100px);
            min-height: calc(100% - 100px);
            overflow-y: scroll;
            background: black;
            width: 100%;
            padding: 10px;
            border: 1px solid rgb(53, 43, 201);

            @media screen and (max-width: 950px) {
                overflow-x: scroll;
            }

            &__oneLog {
                color: white;
                font-weight: 400;
                font-size: 14px;

                @media screen and (max-width: 950px) {
                    min-width: 1000px;
                }
            }

            .green {
                color: #10ff10;
            }

            .purple {
                color: #ff29ff;
            }

            .red {
                color: #ff4646;
            }

            .yellow {
                color: #e0e035;
            }
        }

        &__actions {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            @media screen and (max-width: 550px) {
                padding-top: 10px;
                padding-bottom: 0;
            }

            &__spinner {
                width: 150px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                height: 30px;
                border-radius: 5px;
            }

            &__close {
                background: rgb(53, 43, 201);
                cursor: pointer;
                width: 150px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 5px;
                color: #ffffff;
                border: 2px solid rgb(42, 34, 153);
                transition: 400ms;

                &:hover {
                    box-shadow: 0 0 8px rgb(42, 34, 153);
                }

                &>p {
                    font-size: 15px;
                    font-family: Figtree;

                    @media screen and (max-width: 440px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}