.route__agents {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    padding: 20px;
    overflow-y: scroll;

    @media screen and (max-width: 490px) {
        padding: 10px;
    }

    &__body {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 1fr 2fr 0.8fr 4fr 8fr;
        overflow-y: scroll;

        &__reward {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #D42DCD;
            border-radius: 8px 8px 0 0;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 5px;
            @media screen and (max-width: 750px) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
            }

            @media screen and (max-width: 365px) {
                padding-left: 0;
                padding-right: 0;
            }

            &__left {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 5px;
                @media screen and (max-width: 750px) {
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                }

                &>strong {
                    color: #D42DCD;
                    font-size: 17px;
                    font-weight: 500;
                    @media screen and (max-width: 750px) {
                        font-size: 15px;
                    }
                }

                &>p {
                    color: #D42DCD;
                    font-size: 17px;
                    @media screen and (max-width: 750px) {
                        font-size: 15px;
                    }
                }

                &>img {
                    width: 32px;
                }
            }

            &__right {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                @media screen and (max-width: 750px) {
                    align-items: flex-end;
                    justify-content: flex-end;
                    width: 100%;
                }
                @media screen and (max-width: 475px) {
                    justify-content: center;
                    align-items: center;
                }

                @media screen and (max-width: 380px) {
                    gap: 5px;
                }

                &>p {
                    background: rgb(251, 157, 248);
                    padding: 5px;
                    border: 2px solid rgb(203, 30, 196);
                    color: rgb(203, 30, 196);
                    border-radius: 5px;
                    font-weight: 600;
                    width: 100px;
                    font-size: 17px;
                    text-align: center;
                    @media screen and (max-width: 750px) {
                        font-size: 15px;
                        width: 100px;
                    }

                    @media screen and (max-width: 475px) {
                        padding: 3px;
                        width: 75px;

                        &>small{
                            font-size: 10px;
                        }
                    }
                }
            }
        }

        &__userInfo {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #D42DCD;
            padding: 10px;
            border-radius: 5px 5px 0 0;
            margin-top: 20px;

            @media screen and (max-width: 750px) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
            }

            &__left {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;

                &>img {
                    width: 80px;
                    border-radius: 50%;
                    border: 4px solid #D42DCD;
                    @media screen and (max-width: 750px) {
                        width: 50px;
                    }
                }

                &__span{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;

                    &>strong {
                        color: #D42DCD;
                        font-size: 17px;
                        font-family: Figtree;
                        @media screen and (max-width: 750px) {
                            font-size: 15px;
                        }
                    }

                    &>p {
                        color: #ffffff80;
                        font-size: 13px;
                        @media screen and (max-width: 750px) {
                            font-size: 11px;
                        }
                    }
                }
            }

            &__right {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                background: rgb(251, 157, 248);
                padding: 5px;
                border-radius: 5px;
                border: 2px solid rgb(203, 30, 196);
                color: rgb(203, 30, 196);

                @media screen and (max-width: 950px) {
                    width: 170px;
                    margin-left: auto;
                    margin-right: 0;
                }

                @media screen and (max-width: 750px) {
                    font-size: 15px;
                    justify-content: center;
                }

                @media screen and (max-width: 470px) {
                    padding: 3px;
                    font-size: 14px;
                }

                &>p {
                    font-weight: 600;
                }
            }
        }

        &__filters {
            width: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            height: 100%;

            &__container {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                background: #11182F;
                border-radius: 8px;
                height: 100%;
                width: fit-content;

                &>span {
                    background: #232d4e;
                    height: 100%;
                    width: 50px;
                    padding: 8px;
                    border-radius: 8px;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    &:last-child {
                        width: fit-content;
                    }

                    &>p {
                        font-size: 15px;
                        color: #ffffff;
                        font-family: Figtree;
                    }
                }
            }
        }

        &__topThree {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            width: 100%;
            gap: 10px;

            @media screen and (max-width: 950px) {
                grid-template-columns: 1fr;
            }

            &__box {
                background: #11182F;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 20px;
                padding: 20px;
                border-radius: 8px;
                box-shadow: 1px 1px 3px rgba(142, 134, 252, 0.904);

                @media screen and (max-width: 1490px) {
                    padding: 10px;
                }

                @media screen and (max-width: 750px) {
                    width: 100%;
                }

                &__top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    &>span {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 15px;

                        &>p {
                            font-weight: 500;
                            color: #ffffff;
                            font-size: 15px;
                        }

                        &>img {
                            width: 40px;
                            border-radius: 50%;
                        }
                    }
                }

                &__bottom {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    gap: 20px;

                    @media screen and (max-width: 1350px) {
                        gap: 10px;
                    }


                    &__item {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 5px;
                        width: 100%;

                        &>p {
                            color: #ffffff80;
                            font-size: 13px;

                            @media screen and (max-width: 1490px) {
                                font-size: 12px;
                            }
                        }

                        &>strong {
                            font-size: 16px;
                            color: #ffffff;
                            font-weight: 500;

                            @media screen and (max-width: 1490px) {
                                font-size: 15px;
                            }
                        }

                        &__progress {
                            width: 100%;
                            height: 3px;
                            background: #253463;

                            &__fill {
                                background: rgba(142, 134, 252, 0.904);
                                height: 3px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        &__table {
            width: 100%;
            margin-top: 20px;
            box-shadow: 1px 1px 3px rgba(142, 134, 252, 0.904);
            border-radius: 8px;

            @media screen and (max-width: 900px){
                overflow-x: scroll;
                max-width: 100vw;
                min-height: fit-content;
                max-height: 400px;
                min-height: 400px;
                background: #11182F;
                box-shadow: none;
            }

            &__header {
                width: 100%;
                display: grid;
                grid-template-columns: 0.3fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
                background: #232d4e;
                padding: 10px;
                border-radius: 8px 8px 0 0;
                @media screen and (max-width: 950px) {
                    min-width: 1000px;
                }

                &>p {
                    font-size: 15px;
                    color: #ffffff80;

                    @media screen and (max-width: 1490px) {
                        font-size: 14px;
                    }
                }
            }

            &__content {
                width: 100%;
                max-height: 400px;
                min-height: 400px;
                background: #11182F;
                overflow-y: scroll;
                border-radius: 0 0 8px 8px;

                @media screen and (max-width: 950px) {
                    min-width: 1000px;
                    overflow-x: scroll;
                }

                &__row {
                    display: grid;
                    grid-template-columns: 0.3fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
                    width: 100%;
                    padding: 10px;
                    border-bottom: 1px solid #ffffff80;

                    &>p {
                        color: #ffffff;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}