.route__products{
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    display: grid;
    grid-template-rows: auto 1fr;

    &__header{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        padding: 20px;

        @media screen and (max-width: 725px){
            flex-direction: column;
            gap: 10px;
            padding: 10px;
        }
        
        &__search{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 5px;
            height: 31px;
            background: #192343;
            padding: 4px;
            border-radius: 5px;
            width: 400px;
            border: 1px solid #263565;

            @media screen and (max-width: 1450px){
                width: 250px;
            }

            @media screen and (max-width: 1100px){
                width: 100%
            }

            &>img {
                width: 20px;
                height: 20px;
                border-radius: 5px;
                padding: 2px;
                cursor: pointer;
            }

            &>input {
                height: 100%;
                width: 100%;
                outline: none;
                border: none;
                padding-left: 5px;
                background: #192343;
                color: #FFFFFF;
                font-family: Figtree;

                &::placeholder {
                    color: #FFFFFF;
                    font-family: Figtree;
                }
            }
        }

        &__dropdown {
            position: relative;
            @media screen and (max-width: 725px){
                width: 100%;
            }

            &__body {
                max-height: 0px;
                overflow: hidden;
                position: absolute;
                top: 31px;
                right: 0;
                background: #192343;
                transition: all 0.3s ease;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                width: 100%;
                z-index: 9999;

                &--active {
                    max-height: 1000px;
                    padding: 20px;
                    border-radius: 0 0 5px 5px;
                    border-right: 1px solid #263565;
                    border-left: 1px solid #263565;
                    border-bottom: 1px solid #263565;
                }

                &>p {
                    cursor: pointer;
                    font-size: 14px;
                    width: 100%;
                    padding: 5px;
                    border-bottom: 1px solid #263565;
                    color: #FFFFFF;
                    font-family: Figtree;

                    &:hover {
                        background: #263565;
                        color: rgb(243, 243, 243);
                        transition: 400ms;
                    }
                }
            }

            &__head {
                width: 180px;
                background: #192343;
                padding: 5px;
                border-radius: 5px;
                height: 31px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 1px solid #263565;

                @media screen and (max-width: 1290px){
                    width: 150px;
                }
                @media screen and (max-width: 725px){
                    width: 100%;
                }

                &--active {
                    border-radius: 5px 5px 0 0;
                }

                &>p {
                    font-size: 14px;
                    color: #FFFFFF;
                    font-family: Figtree;
                }
            }
        }

        &__button{
            background: #192343;
            height: 31px;
            background: #192343;
            padding: 4px;
            border-radius: 5px;
            width: fit-content;
            color: #FFFFFF;
            border: 1px solid #263565;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 180px;
            cursor: pointer;

            @media screen and (max-width: 1100px){
                width: 100%;
                &>p{
                    font-size: 14px !important;
                }
            }
        }
    }

    &__body{
        width: 100%;
        padding: 20px;
        height: 100%;

        @media screen and (max-width: 500px) {
            padding: 10px;
        }

        &__table{
            background: #11182F;
            padding: 20px;
            border-radius: 5px;

            @media screen and (max-width: 585px){
                overflow-x: scroll;
                max-width: 90vw;
            }

            &__header{
                display: grid;
                grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr 1fr;
                align-items: center;
                border-bottom: 2px solid #FFFFFF;
                padding-bottom: 5px;

                @media screen and (max-width: 1105px){
                    grid-template-columns: 0.8fr 1.7fr 1fr 1fr 1fr 1fr 1fr;
                }

                @media screen and (max-width: 585px){
                    width: 600px;
                }

                &>p{
                    font-size: 15px;
                    font-family: Figtree;
                    color: #FFFFFF;
                    text-transform: uppercase;
                    font-weight: 500;

                    &:last-child{
                        text-align: end;
                    }
                }
            }

            &__content{
                overflow-y: scroll;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                max-height: 80vh;
                height: 80vh;
                @media screen and (max-width: 585px){
                    width: 600px;
                }

                &__item{
                    width: 100%;
                    display: grid;
                    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr 1fr;
                    align-items: center;
                    border-bottom: 1px solid #8d8a8a98;
                    padding-top: 15px;
                    padding-bottom: 15px;

                    @media screen and (max-width: 1105px){
                        grid-template-columns: 0.8fr 1.7fr 1fr 1fr 1fr 1fr 1fr;
                    }

                    &>img{
                        width: 35px;
                    }

                    &>p{
                        color: #FFFFFF;
                        font-family: Figtree;
                        font-size: 15px;
                    }

                    &__buttons{
                        border-left: 1px solid #8d8a8a98;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 10px;
                        padding-right: 5px;
                        &>img{
                            width: 24px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.newOffer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.514);
    cursor: default;

    &__wrapper{
        width: 700px;
        height: fit-content;
        background: #11182F;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid rgb(53, 43, 201);

        @media screen and (max-width: 730px){
            width: 95%;
        }

        &__header{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &>p{
                font-size: 18px;
                font-family: Figtree;
                color: #FFFFFF;

                @media screen and (max-width: 730px){
                    font-size: 16px;
                }
            }

            &>img{
                width: 24px;
                border: 1px solid #FFFFFF;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        &__body{
            width: 100%;
            display: grid;
            grid-template-rows: 1fr 0.3fr 0.5fr 0.2fr 0.1fr;
            padding-top: 20px;
            gap: 20px;
            &__top{
                background: #101527;
                width: 100%;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid rgb(53, 43, 201);
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;

                &__field{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;

                    &>label{
                        font-family: Figtree;
                        color: #FFFFFF;
                        font-size: 15px;
                        @media screen and (max-width: 730px){
                            font-size: 14px;
                        }
                    }

                    &>input{
                        width: 100%;
                        height: 30px;
                        outline: none;
                        background: transparent;
                        border: none;
                        border-bottom: 2px solid rgb(53, 43, 201);
                        font-family: Figtree;
                        color: #FFFFFF;
                        font-size: 15px;
                    }
                }
            }

            &__middle{
                background: #101527;
                width: 100%;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid rgb(53, 43, 201);
                gap: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                &>p{
                    font-family: Figtree;
                    font-size: 15px;
                    color: #ffffff;
                    @media screen and (max-width: 730px){
                        display: none;
                    }
                }

                &__list{
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    width: 100%;
                    gap: 20px;
                    padding-top: 10px;
                    &>p{
                        font-family: Figtree;
                        font-size: 15px;
                        color: #ffffff;
                        cursor: pointer;
                        border-bottom: 1px solid rgb(53, 43, 201);
                        padding-bottom: 3px;
                    }
                }
            }

            &__bottom{
                background: #101527;
                width: 100%;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid rgb(53, 43, 201);
                gap: 10px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;

                &__field{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;

                    &>label{
                        font-family: Figtree;
                        color: #FFFFFF;
                        font-size: 15px;
                        @media screen and (max-width: 730px){
                            font-size: 14px;
                        }
                    }

                    &>input{
                        width: 100%;
                        height: 30px;
                        outline: none;
                        background: transparent;
                        border: none;
                        border-bottom: 2px solid rgb(53, 43, 201);
                        font-family: Figtree;
                        color: #FFFFFF;
                        font-size: 15px;
                    }
                }
            }

            &__button{
                background: rgb(53, 43, 201);
                cursor: pointer;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 5px;
                color: #ffffff;
                border: 2px solid rgb(42, 34, 153);
                transition: 400ms;

                &:hover {
                    box-shadow: 0 0 8px rgb(42, 34, 153);
                }

                &>p {
                    font-size: 15px;
                    font-family: Figtree;
                }
            }
        }
    }
}

.deleteModal{
    background: rgba(0, 0, 0, 0.39);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__wrapper{
        background: #11182F;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid rgb(53, 43, 201);

        &>p{
            color: #FFFFFF;
            font-family: Figtree;
            font-size: 17px;
        }

        &__box{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            width: 100%;
            margin-top: 10px;

            &__btn{
                background: rgb(53, 43, 201);
                cursor: pointer;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 5px;
                color: #ffffff;
                border: 2px solid rgb(42, 34, 153);
                transition: 400ms;

                &:hover {
                    box-shadow: 0 0 8px rgb(42, 34, 153);
                }

                &>p {
                    font-size: 15px;
                    font-family: Figtree;
                }
            }
        }
    }
}