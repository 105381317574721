.route__publishers {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    display: grid;
    grid-template-rows: auto 1fr;
}

.route__publishers__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.route__publishers {
    padding: 20px;
    @media screen and (max-width: 500px) {
        padding: 10px;
    }
    &__header {
        &__button {
            background: #192343;
            height: 31px;
            background: #192343;
            padding: 4px;
            border-radius: 5px;
            width: fit-content;
            color: #FFFFFF;
            border: 1px solid #263565;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 180px;
            cursor: pointer;

            @media screen and (max-width: 1100px){
                width: 100%;
                &>p{
                    font-size: 14px !important;
                }
            }
        }
    }

    &__table {
        margin-top: 40px;
        width: 100%;
        background: #11182F;
        padding: 20px;
        border-radius: 5px;

        @media screen and (max-width: 585px){
            overflow-x: scroll;
            max-width: 90vw;
        }

        &__header {
            display: grid;
            grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr; 
            align-items: center;
            border-bottom: 2px solid #FFFFFF;
            padding-bottom: 5px;

            @media screen and (max-width: 1105px){
                grid-template-columns: 0.8fr 1.7fr 1fr 1fr 1fr 1fr;
            }

            @media screen and (max-width: 585px){
                width: 1000px;
            }

            &>strong{
                font-size: 15px;
                font-family: Figtree;
                color: #FFFFFF;
                text-transform: uppercase;
                font-weight: 500;

                &:last-child{
                    text-align: end;
                }
            }
        }

        &__content{
            overflow-y: scroll;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 5px;
            max-height: 80vh;
            height: 80vh;
            @media screen and (max-width: 585px){
                width: 1000px;
            }

            &__row{
                width: 100%;
                display: grid;
                grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
                align-items: center;
                border-bottom: 1px solid #8d8a8a98;
                padding-top: 15px;
                padding-bottom: 15px;

                @media screen and (max-width: 1105px){
                    grid-template-columns: 0.8fr 1.7fr 1fr 1fr 1fr 1fr;
                }

                &>img{
                    width: 35px;
                }

                &>p{
                    color: #FFFFFF;
                    font-family: Figtree;
                    font-size: 15px;
                }

                &__buttons{
                    border-left: 1px solid #8d8a8a98;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 15px;
                    padding-right: 5px;
                    &>img{
                        width: 24px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &__statsModal {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.397);
        cursor: default;

        &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            background: #11182F;
            padding: 20px;
            border-radius: 5px;
            border: 1px solid rgb(53, 43, 201);
            min-width: 700px;

            &__head {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-radius: 5px 5px 0 0;
                cursor: default;

                &>h1 {
                    color: #ffffff;
                    font-size: 17px;
                    font-weight: 400;
                    font-family: Figtree;
                }

                &>img {
                    cursor: pointer;
                    width: 24px;
                    border: 1px solid #ffffff;
                    border-radius: 50%;
                }
            }

            &__body {
                width: 100%;
                color: rgb(19, 33, 60);

                &__btn {
                    background: rgb(209, 23, 23);
                    width: 100px;
                    padding: 3px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-left: auto;
                    margin-right: 0;
                    color: #F3F3F3;
                    border-radius: 5px 5px 0 0;
                    cursor: pointer;
                }

                &__leads {
                    width: 100%;

                    &__head {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                        background: #d3d0d0;
                        padding: 5px;
                        align-items: flex-start;

                        &>strong {
                            width: 100%;
                            text-align: center;
                        }
                    }

                    &__values {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: 100%;
                        background: #e7e5e5;
                        padding: 5px;

                        &>p {
                            width: 100%;
                            text-align: center;
                        }
                    }
                }

                &__actions {
                    margin-top: 30px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;

                    &__button {
                        background: rgb(53, 43, 201);
                        cursor: pointer;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        border-radius: 5px;
                        color: #ffffff;
                        border: 2px solid rgb(42, 34, 153);
                        transition: 400ms;
        
                        &:hover {
                            box-shadow: 0 0 8px rgb(42, 34, 153);
                        }
        
                        &>p {
                            font-size: 15px;
                            font-family: Figtree;
                        }
                    }
                }
            }
        }
    }

    &__listLeadsModal {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.397);
        cursor: default;

        &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            background: rgb(19, 33, 60);
            border-radius: 5px;
            min-width: 900px;
            max-width: 900px;
            min-height: 700px;
            max-height: 700px;

            &__head {
                width: 100%;
                background: rgba(0, 0, 0, 0.397);
                padding: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-radius: 5px 5px 0 0;
                cursor: default;

                &>h1 {
                    font-size: 20px;
                    color: #F3F3F3;
                    margin-right: 20px;
                }

                &>img {
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    filter: invert(1);
                }
            }

            &__filters {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                padding: 10px;
                width: 100%;

                &__countries {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    &>p {
                        color: #F3F3F3;
                        cursor: pointer;
                        font-size: 15px;
                    }
                }

                &__type {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    &>p {
                        color: #F3F3F3;
                        cursor: pointer;
                        font-size: 15px;
                    }
                }

                &__buttons {
                    margin-left: auto;
                    margin-right: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;

                    &>p {
                        color: #F3F3F3;
                        cursor: pointer;
                        font-size: 15px;
                    }
                }
            }

            &__body {
                padding: 10px;
                width: 100%;
                color: rgb(19, 33, 60);
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                overflow-y: scroll;
                gap: 10px;

                &__lead {
                    background: rgb(58, 76, 114);
                    color: #F3F3F3;
                    width: 100%;
                    max-width: 300px;
                    padding: 5px;
                    border-radius: 5px;
                    cursor: default;
                    font-size: 16px;
                    font-weight: 400;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;

                    &__status {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 5px;
                    }
                }
            }
        }
    }
}

.component__createModal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.397);
    cursor: default;

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: #11182F;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid rgb(53, 43, 201);
        min-width: 800px;
        max-width: 800px;
        max-height: 800px;

        &__header {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-radius: 5px 5px 0 0;
            cursor: default;

            &>h1 {
                color: #FFFFFF;
                font-size: 17px;
                font-family: Figtree;
                font-weight: 400;
            }

            &>img {
                cursor: pointer;
                width: 24px;
                border: 1px solid #FFFFFF;
                border-radius: 50%;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            gap: 10px;
            position: relative;
            overflow-y: scroll;
            margin-top: 20px;

            &__top{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                background: #101527;
                width: 100%;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid rgb(53, 43, 201);

                &__field{
                    width: 100%;
                    &>input {
                        width: 100%;
                        height: 30px;
                        outline: none;
                        border: none;
                        background: transparent;
                        border-bottom: 2px solid rgb(53, 43, 201);
                        color: #FFFFFF;
                        font-family: Figtree;
                        font-size: 15px;
                    }

                    &>p{
                        color: #FFFFFF;
                        font-family: Figtree;
                        font-size: 15px;
                    }
                }
            }

            &__middle {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 20px;
                background: #101527;
                width: 100%;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid rgb(53, 43, 201);
                max-height: 500px;
                overflow-y: scroll;

                @media screen and (max-width: 800px){
                    grid-template-columns: 1fr;
                }

                &__field{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 3px;

                    &>label{
                        font-size: 13px;
                        font-family: Figtree;
                        color: #ffffff;
                    }
                    &>input{
                        outline: none;
                        border: none;
                        width: 100%;
                        color: #ffffff;
                        font-size: 14px;
                        border-bottom: 2px solid rgb(53, 43, 201);
                        background: none;
                    }
                }
            }

            &__button {
                background: rgb(53, 43, 201);
                cursor: pointer;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 5px;
                color: #ffffff;
                border: 2px solid rgb(42, 34, 153);
                transition: 400ms;

                &:hover {
                    box-shadow: 0 0 8px rgb(42, 34, 153);
                }

                &>p {
                    font-size: 15px;
                    font-family: Figtree;
                }
            }

            &__message {
                position: absolute;
                bottom: 20px;
                left: 20px;
                color: red;
            }
        }
    }
}

.component__exportModal {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.445);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;

    &__wrapper {
        background: #11182F;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid rgb(53, 43, 201);
        width: 700px;
        min-height: fit-content;
        cursor: default;
        @media screen and (max-width: 430px) {
            padding: 10px;
        }

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &>h1 {
                font-size: 17px;
                font-weight: 400;
                color: #ffffff;
                font-family: Figtree;
            }

            &>img {
                width: 24px;
                cursor: pointer;
                border: 1px solid #ffffff;
                border-radius: 50%;
            }
        }

        &__body {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;
            margin-top: 20px;
            @media screen and (max-width: 430px) {
                gap: 10px;
            }

            &__button {
                background: rgb(53, 43, 201);
                cursor: pointer;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 5px;
                color: #ffffff;
                border: 2px solid rgb(42, 34, 153);
                transition: 400ms;

                &:hover {
                    box-shadow: 0 0 8px rgb(42, 34, 153);
                }

                &>p {
                    font-size: 15px;
                    font-family: Figtree;
                    @media screen and (max-width: 430px) {
                        font-size: 14px;
                    }
                }
            }
        }

        &__period{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            color: #ffffff;
            font-size: 15px;
            margin-top: 40px;
            font-family: Figtree;
            background: #101527;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid rgb(53, 43, 201);

            &__top{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                &>input[type='date']{
                    background: transparent;
                    border: none;
                    color: #ffffff;
                    outline: none;

                    &::-webkit-calendar-picker-indicator {
                        background: transparent;
                    }
                }
            }

            &__bottom{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                width: 100%;

                &>input{
                    outline: none;
                    border: none;
                    margin-left: 5px;
                    height: 23px;
                    padding-left: 5px;
                    width: 30%;
                    border-bottom: 2px solid rgb(53, 43, 201);
                    background: transparent;
                    color: #ffffff;

                    &::placeholder{
                        color: #ffffff;
                    }
                }

                &>span{
                    cursor: pointer;
                }
                &>button{
                    cursor: pointer;
                    margin-left: auto;
                    animation-range-start: 0;
                    display: block;
                    background: rgb(53, 43, 201);
                    width: 100px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    border-radius: 5px;
                    color: #ffffff;
                    border: 2px solid rgb(42, 34, 153);
                    transition: 400ms;
    
                    &:hover {
                        box-shadow: 0 0 8px rgb(42, 34, 153);
                    }
    
                    &>p {
                        font-size: 15px;
                        font-family: Figtree;
                    }
                }
            }
        }
    }
}

.component__payouts {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.295);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__wrapper {
        background: rgb(19, 33, 60);
        width: 700px;
        min-height: 700px;
        border-radius: 5px;
        max-height: 800px;
        cursor: default;
        overflow-y: scroll;


        &__header {
            background: rgba(0, 0, 0, 0.397);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            width: 100%;
            border-radius: 5px 5px 0 0;

            &>h1 {
                font-size: 20px;
                font-weight: 600;
                color: #F3F3F3;
            }

            &>img {
                width: 30px;
                height: 30px;
                cursor: pointer;
                filter: invert(1);
            }
        }

        &__body {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 10px;
            padding: 10px;

            &__element {
                background: rgb(58, 76, 114);
                color: #F3F3F3;
                padding: 5px;
                font-weight: 400;
                display: grid;
                grid-template-columns: 0.5fr 1fr;
                font-size: 14px;
            }
        }
    }
}

.component__balanceModal {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.295);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__wrapper {
        background: #11182F;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid rgb(53, 43, 201);
        width: 300px;
        height: fit-content;
        cursor: default;

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-radius: 5px 5px 0 0;

            &>h1 {
                font-size: 17px;
                font-weight: 400;
                color: #ffffff;
                font-family: Figtree;
            }

            &>img {
                width: 24px;
                cursor: pointer;
                border: 1px solid #ffffff;
                border-radius: 50%;
            }
        }

        &__body {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 15px;
            color: #F3F3F3;
            margin-top: 20px;

            &>p {
                font-size: 15px;
                font-weight: 400;
                font-family: Figtree;
                color: #ffffff;
            }

            &__button {
                background: rgb(53, 43, 201);
                cursor: pointer;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 5px;
                color: #ffffff;
                border: 2px solid rgb(42, 34, 153);
                transition: 400ms;

                &:hover {
                    box-shadow: 0 0 8px rgb(42, 34, 153);
                }

                &>p {
                    font-size: 15px;
                    font-family: Figtree;
                }
            }

            &__deposit{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 20px;

                &__button {
                    background: rgb(53, 43, 201);
                    cursor: pointer;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    border-radius: 5px;
                    color: #ffffff;
                    border: 2px solid rgb(42, 34, 153);
                    transition: 400ms;
    
                    &:hover {
                        box-shadow: 0 0 8px rgb(42, 34, 153);
                    }
    
                    &>p {
                        font-size: 15px;
                        font-family: Figtree;
                    }
                }

                &>input{
                    height: 25px;
                    border: none;
                    outline: none;
                    background: transparent;
                    border-bottom: 2px solid rgb(53, 43, 201);
                    color: #ffffff;
                    font-size: 14px;

                    &::placeholder{
                        color: #ffffff;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {

    .component__exportModal__wrapper__period{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
    }

    .component__exportModal__wrapper__period > button{
        width: 100%;
    }
    .component__exportModal__wrapper__period > input{
        width: 100%;
    }

    .component__createModal__wrapper__body,
    .component__payouts__wrapper__body {
        max-height: 500px;
    }

    .component__createModal__wrapper__body__payouts__field>label {
        font-size: 12px;
    }

    .component__createModal__wrapper,
    .component__payouts__wrapper {
        max-width: 98%;
    }

    .component__payouts__wrapper {
        min-height: unset;
    }

    .component__createModal__wrapper__body__payouts,
    .component__payouts__wrapper__body {
        grid-template-columns: 1fr 1fr;
    }

    .component__payouts__wrapper__body__element {
        font-size: 12px;
    }

    .component__createModal__wrapper__body__button {
        font-size: 14px;
    }

    .route__publishers__listLeadsModal__wrapper__filters__type>p,
    .route__publishers__listLeadsModal__wrapper__filters__countries>p,
    .route__publishers__listLeadsModal__wrapper__filters__buttons>p {
        font-size: 14px;
    }

    .route__publishers__listLeadsModal__wrapper__filters__buttons {
        gap: 10px;
    }

    .route__publishers__listLeadsModal__wrapper__filters {
        gap: 10px;
    }

    .route__publishers__listLeadsModal__wrapper__filters {
        padding: 5px;
    }

    .component__exportModal__wrapper {
        max-width: 95%;
    }

    .component__exportModal__wrapper__body__button {
        font-size: 14px;
        text-align: center;
    }

    .route__publishers__statsModal__wrapper {
        min-width: 95%;
    }

    .route__publishers__listLeadsModal__wrapper {
        min-width: 95%;
        max-width: 98%;
        max-height: 500px;
        min-height: 500px;
    }

    .route__publishers__listLeadsModal__wrapper__body {
        grid-template-columns: 1fr;
        align-items: center;
        place-items: center;
    }

    .route__publishers__table__row {
        flex-direction: column;
        border-radius: 5px;

        &>p {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 5px;

            &>p {
                width: 50%;
                margin-bottom: 5px;
                padding: 2px;
                color: #F3F3F3;
                font-weight: 600;
                border-radius: 5px;
            }
        }
    }

    .component__createModal__wrapper {
        min-width: 90%;
    }

    .route__publishers__statsModal__wrapper {
        max-width: 98%;
    }

    .route__publishers__statsModal__wrapper__body {
        padding: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .route__publishers__statsModal__wrapper__body__actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .route__publishers__statsModal__wrapper__body__actions__button {
        width: 100%;
    }

    .route__publishers__statsModal__wrapper__body__actions__button {
        font-size: 14px;
    }

    .route__publishers__statsModal__wrapper__body__leads__values>p {
        font-size: 13px;
    }

    .route__publishers__statsModal__wrapper__body__leads__head>strong {
        font-size: 13px;
    }
}