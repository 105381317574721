.component__sidebar {
    background: #11182F;
    padding: 20px;
    height: 100%;
    max-height: 100vh;
    position: relative;
    transition: left 0.5s ease;
    z-index: 100;

    @media screen and (max-width: 1800px){
        overflow-y: scroll;
    }

    @media screen and (max-width: 1195px){
        overflow: hidden;
        padding: 20px;
        position: absolute;
        left: -400px;

        &__logoMobile{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            background: #11182F;
            padding: 10px;

            &__center{
                margin-left: auto;
                margin-right: auto;
                display: flex;
                flex-direction: column;
                width: fit-content;
                align-items: center;
        
                &>img {
                    width: 35px;
                    height: 35px;
                    filter: invert(1);
                }
        
                &>h1 {
                    font-size: 16px;
                    font-family: Figtree;
                    font-weight: 800;
                    color: #FFFFFF;
                }
            }
        }
    }

    &__logo {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        width: fit-content;
        align-items: center;
        margin-top: 12px;

        &__close{
            width: 25px;
            height: 25px;
            filter: invert(0);
            position: absolute;
            right: 20px;
            top: 20px;
        }

        &__img {
            width: 50px;
            height: 50px;
            filter: invert(1);
        }

        &>h1 {
            font-size: 20px;
            font-family: Figtree;
            font-weight: 800;
            color: #FFFFFF;
        }
    }

    &__nav {
        margin-top: 22px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;

        &__item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
            font-size: 16px;
            font-weight: 400;
            font-family: Figtree;
            color: #FFFFFF;
            cursor: pointer;
            width: 100%;
            padding: 12px;
            border-radius: 2px;
            transition: 400ms;
            border: 2px solid transparent;

            @media screen and (max-width: 1195px){
                padding: 7px;
            }

            @media screen and (max-width: 500px){
                font-size: 14px;
            }


            &:hover {
                background: #192343;
                border: 2px solid #263565;
            }

            &>img {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__appRates {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
        margin-top: 25px;

        &__total {
            width: 100%;

            &__head {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-family: Figtree;
                color: #FFFFFF;

                &>strong{
                    font-weight: 400;
                    font-family: Figtree;

                    @media screen and (max-width: 500px){
                        font-size: 14px;
                    }
                }

                &>small{
                    font-size: 11px;
                    font-family: Figtree;
                }
            }

            &__proccess{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                color: #FFFFFF;
                gap: 5px;

                &>p{
                    font-size: 16px;
                    font-family: Figtree;
                }

                &__line{
                    width: 77%;
                    height: 7px;
                    background: #FFFFFF;

                    &__fill{
                        height: 100%;
                        background: #0D9463;
                    }
                }
            }
        }
    }

    &__logout{
        position: absolute;
        bottom: 20px;
        left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        color: #FFFFFF;
        font-family: Figtree;
        font-weight: 400;
        cursor: pointer;
        font-size: 16px;

        @media screen and (max-width: 1800px){
            position: static;
            margin-top: 10px;
        }

        @media screen and (max-width: 500px){
            font-size: 14px;
        }

        &>img{
            width: 20px;
            height: 20px;
        }
    }
}

.active{
    background: #192343;
    border: 2px solid #263565;
}

.mobile{
    top: 0;
    left: 0;
    width: 230px;
    overflow-y: scroll;
}

