.route__atwork {
    padding: 20px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;

    @media screen and (max-width: 500px) {
        padding: 10px;
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        &>h1 {
            font-size: 18px;
            font-family: Figtree;
            color: #ffffff;
            font-weight: 500;
        }

        &__button {
            background: #192343;
            height: 31px;
            background: #192343;
            padding: 4px;
            border-radius: 5px;
            width: fit-content;
            color: #FFFFFF;
            border: 1px solid #263565;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 180px;
            cursor: pointer;

            @media screen and (max-width: 1100px) {
                width: 100%;

                &>p {
                    font-size: 14px !important;
                }
            }
        }
    }

    &__table {
        background: #11182F;
        padding: 20px;
        border-radius: 5px;
        width: 100%;
        margin-top: 40px;
        max-height: 750px;
        overflow-y: scroll;

        &__header {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            align-items: center;
            padding: 5px;
            border-radius: 5px 5px 0 0;
            border-bottom: 2px solid #ffffff;

            @media screen and (max-width: 710px) {
                grid-template-columns: 1fr 1fr;
            }

            &>p {
                color: #ffffff;
                font-family: Figtree;
                font-size: 15px;
                text-transform: uppercase;
                font-weight: 500;

                &:last-child {
                    text-align: end;
                }
            }
        }

        &__item {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            align-items: center;
            width: 100%;
            gap: 20px;
            padding: 5px;
            color: #FFFFFF;
            border-bottom: 1px solid #8d8a8a98;
            padding-top: 15px;
            padding-bottom: 15px;

            @media screen and (max-width: 710px) {
                grid-template-columns: 1fr 1fr;
            }

            &>p {
                font-size: 15px;
                font-family: Figtree;
            }

            &__buttons {
                border-left: 1px solid rgba(141, 138, 138, 0.5960784314);
                &__btn {
                    background: rgb(53, 43, 201);
                    cursor: pointer;
                    width: 100px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    border-radius: 5px;
                    color: #ffffff;
                    border: 2px solid rgb(42, 34, 153);
                    transition: 400ms;

                    &:hover {
                        box-shadow: 0 0 8px rgb(42, 34, 153);
                    }

                    &>p {
                        font-size: 15px;
                        font-family: Figtree;
                    }

                    margin-left: auto;
                    margin-right: 0;
                }
            }
        }
    }
}

.component__addWorker {
    background: rgba(0, 0, 0, 0.384);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;

    &__wrapper {
        width: 300px;
        height: 500px;
        max-height: 500px;
        overflow-y: scroll;
        background: #11182F;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgb(53, 43, 201);

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &>img {
                width: 24px;
                border-radius: 50%;
                border: 1px solid #ffffff;
                margin-left: auto;
                margin-right: 0;
                cursor: pointer;
                display: block;
            }

            &>p {
                font-size: 17px;
                font-family: Figtree;
                color: #ffffff;

                @media screen and (max-width: 500px) {
                    font-size: 15px;
                }
            }
        }

        &__content {
            background: #101527;
            padding: 10px;
            margin-top: 20px;
            border-radius: 5px;
            border: 1px solid rgb(53, 43, 201);

            &>p {
                color: #ffffff;
                font-size: 15px;
                font-family: Figtree;
                margin-bottom: 5px;
                border-bottom: 2px solid rgb(53, 43, 201);
                padding-bottom: 5px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 1529px) {
    .route__atwork__table {
        width: 100%;
    }
}