.route__kanban {
    width: 100%;
    min-height: 100%;
    background: #080B16;
    display: grid;
    grid-template-rows: auto 1fr;

    &__header {
        width: 100%;
        padding: 20px;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: 1290px) {
            padding: 10px;
        }

        &__statuses {
            background: #192343;
            height: 31px;
            background: #192343;
            padding: 4px;
            border-radius: 5px;
            width: fit-content;
            color: #FFFFFF;
            border: 1px solid #263565;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 180px;
            cursor: pointer;

            @media screen and (max-width: 1100px) {
                width: 100%;

                &>p {
                    font-size: 14px !important;
                }
            }
        }

        &__cdropdown {
            position: relative;

            &__head {
                height: 31px;
                width: 180px;
                background: #192343;
                padding: 5px;
                border-radius: 5px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 1px solid #263565;

                @media screen and (max-width: 1290px) {
                    width: 130px;
                }

                &>p {
                    font-size: 14px;
                    color: #FFFFFF;
                    font-family: Figtree;
                }

                &--active {
                    border-radius: 5px 5px 0 0;
                }
            }


            &__body {
                max-height: 0px;
                overflow: hidden;
                position: absolute;
                top: 31px;
                right: 0;
                background: #192343;
                transition: all 0.3s ease;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                width: 100%;

                &>p {
                    cursor: pointer;
                    font-size: 14px;
                    padding: 5px;
                    width: 100%;
                    border-bottom: 1px solid #263565;
                    color: #FFFFFF;
                    font-family: Figtree;

                    &:hover {
                        background: #263565;
                        color: rgb(243, 243, 243);
                        transition: 400ms;
                    }
                }

                &--active {
                    max-height: 1000px;
                    padding: 20px;
                    border-radius: 0 0 5px 5px;
                    border-right: 1px solid #263565;
                    border-left: 1px solid #263565;
                    border-bottom: 1px solid #263565;
                }
            }
        }

        &__dropdown {
            position: relative;

            &__body {
                max-height: 0px;
                overflow: hidden;
                position: absolute;
                top: 31px;
                right: 0;
                background: #192343;
                transition: all 0.3s ease;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                width: 100%;

                &--active {
                    max-height: 1000px;
                    padding: 20px;
                    border-radius: 0 0 5px 5px;
                    border-right: 1px solid #263565;
                    border-left: 1px solid #263565;
                    border-bottom: 1px solid #263565;
                }

                &>p {
                    cursor: pointer;
                    font-size: 14px;
                    width: 100%;
                    padding: 5px;
                    border-bottom: 1px solid #263565;
                    color: #FFFFFF;
                    font-family: Figtree;

                    &:hover {
                        background: #263565;
                        color: rgb(243, 243, 243);
                        transition: 400ms;
                    }
                }
            }

            &__head {
                width: 180px;
                background: #192343;
                padding: 5px;
                border-radius: 5px;
                height: 31px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 1px solid #263565;

                @media screen and (max-width: 1290px) {
                    width: 150px;
                }

                &--active {
                    border-radius: 5px 5px 0 0;
                }

                &>p {
                    font-size: 14px;
                    color: #FFFFFF;
                    font-family: Figtree;
                }
            }
        }

        &__searchBar {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 5px;
            height: 31px;
            background: #192343;
            padding: 4px;
            border-radius: 5px;
            width: 400px;
            border: 1px solid #263565;

            @media screen and (max-width: 1450px) {
                width: 250px;
            }

            @media screen and (max-width: 1100px) {
                width: 100%
            }

            &>img {
                width: 20px;
                height: 20px;
                border-radius: 5px;
                padding: 2px;
                cursor: pointer;
            }

            &>input {
                height: 100%;
                width: 100%;
                outline: none;
                border: none;
                padding-left: 5px;
                background: #192343;
                color: #FFFFFF;
                font-family: Figtree;

                &::placeholder {
                    color: #FFFFFF;
                    font-family: Figtree;
                }
            }
        }
    }

    &__content {
        display: grid;
        padding: 20px;
        overflow-x: scroll;
        max-width: calc(100vw - 300px);
        min-height: 80vh;
        height: 100%;

        @media screen and (max-width: 1290px) {
            padding: 10px;
        }

        &__column {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 300px;
            max-height: calc(100vh - 115px);

            &__filtered {
                width: 100%;
                padding: 5px;
                border-radius: 5px;
                margin-bottom: 10px;
                background: #080B16;
                color: #FFFFFF;
                font-family: Figtree;
            }

            &__header {
                width: 100%;
                padding: 5px;
                border-radius: 5px;
                margin-bottom: 10px;

                &>p {
                    color: #FFFFFF;
                    font-family: Figtree;
                }
            }

            &__body {
                overflow-y: scroll;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;

                &__lead {
                    background: #11182F;
                    width: 98%;
                    padding: 10px;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;
                    cursor: pointer;

                    &>span {
                        font-family: Figtree;
                        background: rgba(142, 134, 252, 0.904);
                        width: fit-content;
                        padding-left: 10px;
                        padding-right: 10px;
                        height: 20px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50px;
                        color: rgb(53, 43, 201);
                        font-size: 15px;
                        font-weight: 600;
                        border: 1px solid rgb(72, 63, 215);
                    }

                    &>p {
                        font-family: Figtree;
                        color: #FFFFFF;
                        font-size: 15px;
                        @media screen and (max-width: 440px) {
                            font-size: 14px;
                        }
                    }

                    &__offer {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        &>strong {
                            font-family: Figtree;
                            color: #FFFFFF;
                            font-size: 18px;

                            @media screen and (max-width: 440px) {
                                font-size: 16px;
                            }
                        }
                    }

                    &>img {
                        width: 40px;
                        height: 20px;
                    }
                }

                &__paginationBtn {
                    width: 98%;
                    background: rgb(72, 63, 215);
                    border: none;
                    cursor: pointer;
                    font-size: 15px;
                    padding: 5px;
                    border-radius: 5px;
                    color: #FFFFFF;
                    font-family: Figtree;
                }
            }
        }
    }
}

.route__kanban__notifications {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 15px;
    cursor: pointer;
    background: rgb(19, 33, 60);
    border-radius: 20px 0 0 0;
}

@media screen and (max-width: 700px) {
    .route__kanban__notifications {
        display: none;
    }
}

.filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    @media screen and (max-width: 600px){
        max-height: 0px;
        transition: all .5s ease-in-out;
        overflow: hidden;
    }

    &__showFilters {
        background: #192343;
        height: 25px;
        background: #192343;
        padding: 4px;
        border-radius: 5px;
        width: -moz-fit-content;
        width: fit-content;
        color: #FFFFFF;
        border: 1px solid #263565;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        cursor: pointer;
        &>p{
            font-size: 14px;
            color: #FFFFFF;
            font-family: Figtree;
        }
    }
}

.filtersActive{
    max-height: 500px;
    margin-top: 15px;
}

.filters>p {
    font-size: 18px !important;
    text-transform: none !important;
}

.route__kanban__leadInfo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.397);
    cursor: default;
}

.route__kanban__leadInfo__wrapper {
    background: rgb(111, 119, 136);
    border-radius: 5px;
    min-width: 800px;
    max-width: 800px;
    min-height: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.route__kanban__leadInfo__wrapper__header {
    width: 100%;
    background: rgb(19, 33, 60);
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0 0;
}

.route__kanban__leadInfo__wrapper__header>img {
    cursor: pointer;
    filter: invert(1);
    width: 24px;
    height: 24px;
}

.route__kanban__leadInfo__wrapper__header h1 {
    font-size: 20px;
    color: #F3F3F3;
}

.route__kanban__leadInfo__wrapper__header>button {
    height: 30px;
    margin-left: 20px;
    margin-right: auto;
    color: white;
    background: rgb(230, 48, 48);
    border: none;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.route__kanban__leadInfo__wrapper__body {
    width: 100%;
    padding: 20px;
    overflow-y: scroll;
    max-height: calc(100% - 250px);
}

.route__kanban__leadInfo__wrapper__body__responsible {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 5px;
    color: #F3F3F3;
}

.route__kanban__leadInfo__wrapper__body__responsible__button {
    background: rgb(19, 33, 60);
    padding: 3px;
    font-size: 15px;
    border-radius: 5px;
    font-weight: 600;
    height: 30px;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 400ms;
    color: #F3F3F3;
}

.route__kanban__leadInfo__wrapper__body__responsible__button:hover {
    background: rgb(41, 59, 94);
}

.route__kanban__leadInfo__wrapper__body__input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.route__kanban__leadInfo__wrapper__body__input textarea {
    outline: none;
    background: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}

.route__kanban__leadInfo__wrapper__body__quantity {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.route__kanban__leadInfo__wrapper__body__quantity input {
    border: none;
    outline: none;
    height: 35px;
    padding-left: 10px;
    border-radius: 5px;
}

.route__kanban__leadInfo__wrapper__body__quantity button {
    border: none;
    border-radius: 5px;
    background: rgb(19, 33, 60);
    color: white;
    font-weight: 700;
    cursor: pointer;
    transition: 400ms;
    width: 100px;
    height: 35px;
    padding: 5px;
}

.route__kanban__leadInfo__wrapper__body__quantity button:hover {
    background: rgb(41, 59, 94);
}

.route__kanban__leadInfo__wrapper__body__quantity>p {
    font-weight: 400 !important;
    color: #F3F3F3;
}

.route__kanban__leadInfo__wrapper__body__item {
    width: 100%;
    background: rgb(19 33 60 / 59%);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
}

.route__kanban__leadInfo__wrapper__body__item>p {
    font-weight: 400 !important;
    color: #F3F3F3;
}

.route__kanban__leadInfo__wrapper__body__item>small {
    font-weight: 400 !important;
    color: #F3F3F3;
}

.route__kanban__leadInfo__wrapper__body__item>strong {
    color: #F3F3F3;
}

.route__kanban__leadInfo__wrapper__body__stepOne {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    height: 100%;
    color: #F3F3F3;
}

.route__kanban__leadInfo__wrapper__body__stepOne>p {
    background: rgb(19, 33, 60);
    width: 100%;
    padding: 3px;
}

.route__kanban__leadInfo__wrapper__body__stepOne__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.route__kanban__leadInfo__wrapper__body__stepFour {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.route__kanban__leadInfo__wrapper__body__stepFour__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 100%;
}

.route__kanban__leadInfo__wrapper__body__stepFour__form__field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
}

.route__kanban__leadInfo__wrapper__body__stepFour__form__field>input {
    width: 100%;
    height: 35px;
    outline: none;
    border: none;
    background: #F3F3F3;
    font-size: 15px;
    padding-left: 5px;
}

.route__kanban__leadInfo__wrapper__body__stepFour__form__field>label {
    font-size: 16px;
    color: #F3F3F3;
}

.route__kanban__leadInfo__wrapper__body__stepFour__note {
    background: #EF9500;
    padding: 5px;
    font-size: 16px;
}

.route__kanban__leadInfo__wrapper__scheduleCall {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 20px;
    background: rgb(19, 33, 60);
}

.route__kanban__leadInfo__wrapper__scheduleCall__left>h1,
.route__kanban__leadInfo__wrapper__scheduleCall__right>h1 {
    font-size: 18px;
    color: white;
}

.route__kanban__leadInfo__wrapper__scheduleCall__left,
.route__kanban__leadInfo__wrapper__scheduleCall__right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.route__kanban__leadInfo__wrapper__scheduleCall__left__btn {
    background: white;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
}

.route__kanban__leadInfo__wrapper__scheduleCall__right__btn {
    background: white;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
}

.extensions {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.397);
    cursor: default;
}

.extensions__wrapper {
    width: 700px;
    max-height: 700px;
    min-height: 700px;
    background: rgb(111, 119, 136);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
}

.extensions__wrapper__list {
    overflow-y: scroll;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    gap: 15px;
}

.extensions__wrapper__header>img {
    width: 30px;
    filter: invert(1);
}

.extensions__wrapper__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: rgb(19, 33, 60);
    color: white;
    padding: 10px;
    border-radius: 5px 5px 0 0;
}

.extensions__wrapper__header__h1 {
    font-size: 24px;
}

.extensions__wrapper__list__lead {
    background: rgb(19, 33, 60);
    padding: 10px;
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.extensions__wrapper__list__lead>img {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
}

.extensions__wrapper__list__lead>p {
    color: white;
}

.component__agentList {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.288);
    cursor: default;
}

.component__agentList__wrapper {
    width: 300px;
    max-height: 500px;
    min-height: 500px;
    overflow-y: scroll;
    background: rgb(19, 33, 60);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    font-size: 15px;
    color: #F3F3F3;
    border-radius: 5px;
}

.component__agentList__wrapper>p {
    cursor: pointer;
    border-bottom: 2px solid #F3F3F3;
    width: 100%;
    padding-bottom: 3px;
}

.component__agentList__wrapper>img {
    cursor: pointer;
    filter: invert(1);
    width: 34px;
    margin-left: auto;
    margin-right: 0;
    display: block;
}

@media screen and (max-width: 1500px) {
    .route__kanban__content {
        max-width: calc(100vw - 200px);
    }
}

@media screen and (max-width: 1300px) {
    .route__kanban__content {
        max-width: 100vw;
    }
}

@media screen and (max-width: 775px) {
    .route__kanban__header {
        flex-direction: column;
        height: fit-content;
        padding: 10px;
        justify-content: flex-start;
        gap: 0;
    }

    .route__kanban__header p {
        font-size: 15px;
        font-family: Figtree;
    }

    .route__kanban__header__dropdown__head>p {
        font-size: 15px;
        font-family: Figtree;
    }

    .route__kanban__header__cdropdown__head>p {
        font-size: 15px;
        font-family: Figtree;
    }

    .route__kanban__header__cdropdown__head {
        width: 100px !important;
    }

    .route__kanban__header__dropdown__head {
        width: 160px !important;
    }
}

@media screen and (max-width: 727px) {
    .route__kanban__leadInfo__wrapper {
        max-width: 98% !important;
        min-width: 98% !important;
        width: 98% !important;
        min-height: 600px;
        height: 600px !important;
    }

    .route__kanban__leadInfo__wrapper__body__quantity {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
    }

    .route__kanban__leadInfo__wrapper__body__quantity input {
        width: 100%;
    }

    .route__kanban__leadInfo__wrapper__body__item>p {
        word-break: break-all;
    }

    .route__kanban__content__column {
        max-height: fit-content;
    }

    .route__kanban__content {
        overflow-y: scroll;
        max-height: 100vh;
    }

    .route__kanban__content__column__list {
        overflow-y: unset;
    }

    .route__kanban__leadInfo__wrapper__body {
        padding: 5px;
    }

    .route__kanban__leadInfo__wrapper__header {
        padding: 10px;
    }

    .route__kanban__leadInfo__wrapper__body__responsible__button {
        font-size: 12px;
        text-align: center;
        height: 35px;
    }

    .route__kanban__leadInfo__wrapper__body__stepOne__buttons {
        gap: 5px;
    }

    .route__kanban__leadInfo__wrapper__body__stepOne>p {
        font-size: 14px;
    }

    .route__kanban__leadInfo__wrapper__scheduleCall__left>h1,
    .route__kanban__leadInfo__wrapper__scheduleCall__right>h1 {
        font-size: 16px;
    }

    .route__kanban__leadInfo__wrapper__scheduleCall__right__btn,
    .route__kanban__leadInfo__wrapper__scheduleCall__left__btn {
        font-size: 14px;
    }

    .route__kanban__leadInfo__wrapper__body__item>p {
        font-size: 14px;
    }

    .route__kanban__leadInfo__wrapper__body__stepFour__form {
        grid-template-columns: 1fr;
    }

    .route__kanban__leadInfo__wrapper__body__stepFour__note,
    .route__kanban__leadInfo__wrapper__body__stepFour__form__field>label {
        font-size: 14px;
    }
}

@media screen and (max-width: 500px) {
    .route__kanban__leadInfo__wrapper__header h1 {
        font-size: 16px;
        order: 2;
    }

    .route__kanban__leadInfo__wrapper__header>button {
        order: 1;
        margin-left: 0;
        margin-right: 0;
    }

    .route__kanban__leadInfo__wrapper__header>img {
        order: 3;
    }

    .filters {
        flex-direction: column;
        width: 100%;
    }

    .route__kanban__header__dropdown__head,
    .route__kanban__header__dropdown,
    .route__kanban__header__cdropdown__head,
    .route__kanban__header__cdropdown,
    .route__kanban__header__searchBar {
        width: 100% !important;
    }

    .route__kanban__header__dropdown__body {
        z-index: 1000;
    }

    .route__kanban__header>p {
        display: none;
    }
}