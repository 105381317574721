.route__finances {
    width: 100%;
    padding: 20px;
    height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr;

    @media screen and (max-width: 592px) {
        padding: 10px;
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: 762px) {
            gap: 10px;
        }

        &__dropdown {
            position: relative;

            @media screen and (max-width: 725px) {
                width: 100%;
            }

            &__body {
                max-height: 0px;
                overflow: hidden;
                position: absolute;
                top: 31px;
                right: 0;
                background: #192343;
                transition: all 0.3s ease;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                width: 100%;
                z-index: 9999;

                &--active {
                    max-height: 1000px;
                    padding: 20px;
                    border-radius: 0 0 5px 5px;
                    border-right: 1px solid #263565;
                    border-left: 1px solid #263565;
                    border-bottom: 1px solid #263565;
                }

                &>p {
                    cursor: pointer;
                    font-size: 14px;
                    width: 100%;
                    padding: 5px;
                    border-bottom: 1px solid #263565;
                    color: #FFFFFF;
                    font-family: Figtree;

                    &:hover {
                        background: #263565;
                        color: rgb(243, 243, 243);
                        transition: 400ms;
                    }
                }
            }

            &__head {
                width: 200px;
                background: #192343;
                padding: 5px;
                border-radius: 5px;
                height: 31px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 1px solid #263565;

                @media screen and (max-width: 725px) {
                    width: 100%;
                }

                &--active {
                    border-radius: 5px 5px 0 0;
                }

                &>p {
                    font-size: 14px;
                    color: #FFFFFF;
                    font-family: Figtree;
                }
            }
        }

        &__button {
            background: #192343;
            height: 31px;
            background: #192343;
            padding: 4px;
            border-radius: 5px;
            width: fit-content;
            color: #FFFFFF;
            border: 1px solid #263565;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 180px;
            cursor: pointer;

            @media screen and (max-width: 1430px) {
                width: 200px;
            }

            @media screen and (max-width: 700px) {
                width: 100%;

                &>p {
                    font-size: 14px !important;
                }
            }
        }
    }

    &__body {
        display: grid;
        grid-template-rows: auto auto auto 1fr;

        &__filters {
            width: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            height: 100%;

            @media screen and (max-width: 762px) {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            &__container {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                background: #11182F;
                border-radius: 8px;
                height: 100%;
                width: fit-content;

                &>span {
                    background: #232d4e;
                    height: 100%;
                    width: 50px;
                    padding: 8px;
                    border-radius: 8px;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    &:last-child {
                        width: fit-content;
                    }

                    &>p {
                        font-size: 15px;
                        color: #ffffff;
                        font-family: Figtree;
                    }
                }
            }
        }

        &__kpis {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 20px;
            padding-top: 10px;

            @media screen and (max-width: 1000px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media screen and (max-width: 762px) {
                grid-template-columns: 1fr 1fr;
                gap: 10px;
                padding-top: 0px;
            }

            @media screen and (max-width: 492px) {
                grid-template-columns: 1fr;
            }


            &__kpi {
                background: #11182F;
                min-height: 90px;
                max-height: 90px;
                width: 100%;
                padding: 20px;
                border-radius: 5px;
                border: 1px solid #263565;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                &>img {
                    width: 46px;

                    @media screen and (max-width: 492px) {
                        width: 38px;
                    }
                }

                &>span {
                    color: #ffffff;
                    font-family: Figtree;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;

                    &>p {
                        font-size: 13px;

                        @media screen and (max-width: 492px) {
                            font-size: 12px;
                        }

                        &:last-child {
                            font-size: 20px;
                            font-weight: 600;

                            @media screen and (max-width: 1270px) {
                                font-size: 18px;
                            }

                            @media screen and (max-width: 492px) {
                                font-size: 16px;
                            }
                        }

                    }
                }
            }
        }

        &__tableNames{
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            gap: 20px;
            margin-top: 20px;
            &>h1{
                font-size: 17px;
                color: #ffffff;
                margin-bottom: 10px;
                font-weight: 400;
            }
        }

        &__tables {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            width: 100%;

            @media screen and (max-width: 920px){
                grid-template-columns: 1fr;
                grid-template-rows: 1fr auto 1fr;
                gap: 0;
            }

            &__expenses {
                width: 100%;
                background: #11182F;
                padding: 20px;
                border-radius: 5px;
    
                @media screen and (max-width: 460px){
                    overflow-x: scroll;
                    max-width: 100vw;
                }

                &__header {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    align-items: center;
                    border-bottom: 2px solid #FFFFFF;
                    padding-bottom: 5px;

                    @media screen and (max-width: 460px){
                        width: 500px;
                    }

                    &>p{
                        font-size: 15px;
                        font-family: Figtree;
                        color: #FFFFFF;
                        text-transform: uppercase;
                        font-weight: 500;
    
                        &:last-child{
                            text-align: end;
                        }
                    }
                }

                &__content{
                    overflow-y: scroll;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                    max-height: 60vh;
                    @media screen and (max-width: 460px){
                        width: 500px;
                    }
    
                    &__item{
                        width: 100%;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        align-items: center;
                        border-bottom: 1px solid #8d8a8a98;
                        padding-top: 15px;
                        padding-bottom: 15px;
    
                        @media screen and (max-width: 1105px){
                            grid-template-columns: 1fr 1fr 1fr;
                        }
    
                        &>img{
                            width: 35px;
                        }
    
                        &>p{
                            color: #FFFFFF;
                            font-family: Figtree;
                            font-size: 15px;
                            &:last-child{
                                text-align: end;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            &__returnedOrders {
                width: 100%;
                background: #11182F;
                padding: 20px;
                border-radius: 5px;
    
                @media screen and (max-width: 460px){
                    overflow-x: scroll;
                    max-width: 100vw;
                }

                &__header {
                    display: grid;
                    grid-template-columns: 0.3fr 2fr 1fr 1fr;
                    align-items: center;
                    border-bottom: 2px solid #FFFFFF;
                    padding-bottom: 5px;

                    @media screen and (max-width: 460px){
                        width: 500px;
                    }

                    &>p{
                        font-size: 15px;
                        font-family: Figtree;
                        color: #FFFFFF;
                        text-transform: uppercase;
                        font-weight: 500;
    
                        &:last-child{
                            text-align: end;
                        }
                    }
                }

                &__content{
                    overflow-y: scroll;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                    max-height: 60vh;
                    @media screen and (max-width: 460px){
                        width: 500px;
                    }
    
                    &__item{
                        width: 100%;
                        display: grid;
                        grid-template-columns: 0.3fr 2fr 1fr 1fr;
                        align-items: center;
                        border-bottom: 1px solid #8d8a8a98;
                        padding-top: 15px;
                        padding-bottom: 15px;
    
                        @media screen and (max-width: 1105px){
                            grid-template-columns: 0.3fr 2fr 1fr 1fr;
                        }
    
                        &>img{
                            width: 35px;
                        }
    
                        &>p{
                            color: #FFFFFF;
                            font-family: Figtree;
                            font-size: 15px;
                            &:last-child{
                                text-align: end;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.component__expensesModal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.514);
    cursor: default;

    &__wrapper {
        width: 700px;
        height: fit-content;
        background: #11182F;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid rgb(53, 43, 201);

        @media screen and (max-width: 730px) {
            width: 95%;
        }

        @media screen and (max-width: 520px) {
            overflow-y: scroll;
            max-height: 500px;
            padding: 10px;
        }

        &__header {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &>p {
                font-size: 18px;
                font-family: Figtree;
                color: #FFFFFF;

                @media screen and (max-width: 730px) {
                    font-size: 16px;
                }
            }

            &>img {
                width: 24px;
                border: 1px solid #FFFFFF;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        &__create {
            background: #101527;
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid rgb(53, 43, 201);
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            @media screen and (max-width: 520px) {
                grid-template-columns: 1fr;
            }

            &__dropdown {
                position: relative;

                @media screen and (max-width: 725px) {
                    width: 100%;
                }

                &__body {
                    max-height: 0px;
                    overflow: hidden;
                    position: absolute;
                    top: 31px;
                    right: 0;
                    background: #192343;
                    transition: all 0.3s ease;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;
                    width: 100%;
                    z-index: 9999;

                    &--active {
                        max-height: 1000px;
                        padding: 20px;
                        border-radius: 0 0 5px 5px;
                        border-right: 1px solid #263565;
                        border-left: 1px solid #263565;
                        border-bottom: 1px solid #263565;
                    }

                    &>p {
                        cursor: pointer;
                        font-size: 14px;
                        width: 100%;
                        padding: 5px;
                        border-bottom: 1px solid #263565;
                        color: #FFFFFF;
                        font-family: Figtree;

                        &:hover {
                            background: #263565;
                            color: rgb(243, 243, 243);
                            transition: 400ms;
                        }
                    }
                }

                &__head {
                    width: 100%;
                    background: #192343;
                    padding: 5px;
                    border-radius: 5px;
                    height: 31px;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #263565;

                    @media screen and (max-width: 725px) {
                        width: 100%;
                    }

                    &--active {
                        border-radius: 5px 5px 0 0;
                    }

                    &>p {
                        font-size: 14px;
                        color: #FFFFFF;
                        font-family: Figtree;
                    }
                }
            }

            &__field {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;

                &>label {
                    font-size: 15px;
                }

                &>input {
                    width: 100%;
                    height: 30px;
                    border: none;
                    outline: none;
                    border-bottom: 2px solid rgb(53, 43, 201);
                    background: transparent;
                    color: #FFFFFF;
                }
            }

            &__btn {
                background: rgb(53, 43, 201);
                cursor: pointer;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 5px;
                color: #ffffff;
                border: 2px solid rgb(42, 34, 153);
                transition: 400ms;

                &:hover {
                    box-shadow: 0 0 8px rgb(42, 34, 153);
                }

                &>p {
                    font-size: 15px;
                    font-family: Figtree;
                }
            }
        }

        &__body {
            width: 100%;

            &__list {
                width: 100%;
                background: #101527;
                min-height: 400px;
                max-height: 400px;
                border: 1px solid rgb(53, 43, 201);
                border-radius: 5px;
                margin-top: 20px;
                padding: 10px;
                overflow-y: scroll;

                &__row {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    gap: 20px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #8d8a8a98;

                    @media screen and (max-width: 520px) {
                        grid-template-columns: 0.3fr 2fr 1fr 0.3fr;
                    }

                    &>p {
                        font-size: 15px;
                    }

                    &>img {
                        width: 24px;
                        cursor: pointer;
                        margin-left: auto;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}