.route__statistics {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 20px;

    @media screen and (max-width: 490px) {
        padding: 10px;
    }

    &__header {
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: 1225px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            width: 100%;
        }

        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr 1fr;
            width: 100%;
        }

        @media screen and (max-width: 490px) {
            gap: 10px;
        }

        @media screen and (max-width: 400px) {
            grid-template-columns: 1fr;
        }

        &__search {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 5px;
            height: 31px;
            background: #192343;
            padding: 4px;
            border-radius: 5px;
            width: 200px;
            border: 1px solid #263565;

            @media screen and (max-width: 1570px) {
                width: 150px;
            }

            @media screen and (max-width: 1430px) {
                width: 150px;
            }

            @media screen and (max-width: 1225px) {
                width: 100%;
            }

            &>img {
                width: 20px;
                height: 20px;
                border-radius: 5px;
                padding: 2px;
                cursor: pointer;
            }

            &>input {
                height: 100%;
                width: 100%;
                outline: none;
                border: none;
                padding-left: 5px;
                background: #192343;
                color: #FFFFFF;
                font-family: Figtree;

                &::placeholder {
                    color: #FFFFFF;
                    font-family: Figtree;
                }
            }
        }

        &__button {
            background: #192343;
            height: 31px;
            background: #192343;
            padding: 4px;
            border-radius: 5px;
            width: fit-content;
            color: #FFFFFF;
            border: 1px solid #263565;
            font-size: 14px;
            display: flex;
            width: 180px;
            cursor: pointer;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            @media screen and (max-width: 1430px) {
                width: 140px;
            }

            @media screen and (max-width: 1225px) {
                width: 100%;
            }

            @media screen and (max-width: 1100px) {
                width: 100%;

                &>p {
                    font-size: 14px !important;
                }
            }
        }

        &__dropdown {
            position: relative;

            @media screen and (max-width: 725px) {
                width: 100%;
            }

            &__body {
                max-height: 0px;
                overflow: hidden;
                position: absolute;
                top: 31px;
                right: 0;
                background: #192343;
                transition: all 0.3s ease;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                width: 100%;
                z-index: 9999;

                &--active {
                    max-height: 500px;
                    padding: 20px;
                    border-radius: 0 0 5px 5px;
                    border-right: 1px solid #263565;
                    border-left: 1px solid #263565;
                    border-bottom: 1px solid #263565;
                    overflow-y: scroll;
                }

                &>p {
                    cursor: pointer;
                    font-size: 13px;
                    width: 100%;
                    padding: 5px;
                    border-bottom: 1px solid #263565;
                    color: #FFFFFF;
                    font-family: Figtree;

                    &:hover {
                        background: #263565;
                        color: rgb(243, 243, 243);
                        transition: 400ms;
                    }
                }
            }

            &__head {
                width: 230px;
                background: #192343;
                padding: 5px;
                border-radius: 5px;
                height: 31px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                border: 1px solid #263565;

                @media screen and (max-width: 1585px) {
                    width: 200px;
                }

                @media screen and (max-width: 1478px) {
                    width: 150px;
                }

                @media screen and (max-width: 1225px) {
                    width: 100%;
                }

                @media screen and (max-width: 725px) {
                    width: 100%;
                }

                &--active {
                    border-radius: 5px 5px 0 0;
                }

                &>p {
                    font-size: 14px;
                    color: #FFFFFF;
                    font-family: Figtree;
                }
            }
        }
    }

    &__content {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 20px;
        margin-top: 20px;

        @media screen and (max-width: 1450px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media screen and (max-width: 844px) {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 490px) {
            grid-template-columns: 1fr;
        }

        &__column {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            width: 100%;
            background: #192343;
            padding: 10px;
            border-radius: 5px;
            box-shadow: 0 0 4px black;
            height: fit-content;


            &>h1 {
                font-size: 17px;
                color: #FFFFFF;
                font-family: Figtree;
                font-weight: 500;

                @media screen and (max-width: 490px) {
                    font-size: 16px;
                }
            }

            &__grid {
                display: grid;
                grid-template-rows: 1fr 1fr 1fr 1fr;
                gap: 20px;
                width: 100%;

                @media screen and (max-width: 490px) {
                    gap: 10px;
                }

                &__kpi {
                    background: #11182F;
                    min-height: 70px;
                    border-radius: 5px;
                    padding: 5px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    @media screen and (max-width: 490px) {
                        min-height: 50px;
                    }

                    &__left {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 5px;

                        &>p {
                            color: #FFFFFF;
                            font-size: 13px;
                            font-family: Figtree;
                        }

                        &>strong {
                            font-size: 20px;
                            color: #FFFFFF;
                            font-family: Figtree;

                            @media screen and (max-width: 490px) {
                                font-size: 17px;
                            }
                        }
                    }
                }
            }
        }
    }

    &__extensions {
        padding-top: 20px;

        &>h1 {
            font-size: 20px;
            color: #FFFFFF;
            font-family: Figtree;
            font-weight: 400;

            @media screen and (max-width: 490px) {
                font-size: 17px;
            }
        }

        &__content {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap: 20px;
            margin-top: 20px;

            @media screen and (max-width: 1450px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media screen and (max-width: 844px) {
                grid-template-columns: 1fr 1fr;
            }

            @media screen and (max-width: 490px) {
                grid-template-columns: 1fr;
            }

            &__column {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 20px;
                width: 100%;
                background: #192343;
                padding: 10px;
                border-radius: 5px;
                box-shadow: 0 0 4px black;
                height: fit-content;


                &>h1 {
                    font-size: 17px;
                    color: #FFFFFF;
                    font-family: Figtree;
                    font-weight: 500;

                    @media screen and (max-width: 490px) {
                        font-size: 16px;
                    }
                }

                &__grid {
                    display: grid;
                    grid-template-rows: 1fr 1fr 1fr;
                    gap: 20px;
                    width: 100%;

                    @media screen and (max-width: 490px) {
                        gap: 10px;
                    }

                    &__kpi {
                        background: #11182F;
                        min-height: 70px;
                        border-radius: 5px;
                        padding: 5px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        @media screen and (max-width: 490px) {
                            min-height: 50px;
                        }

                        &__left {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap: 5px;

                            &>p {
                                color: #FFFFFF;
                                font-size: 13px;
                                font-family: Figtree;
                            }

                            &>strong {
                                font-size: 20px;
                                color: #FFFFFF;
                                font-family: Figtree;

                                @media screen and (max-width: 490px) {
                                    font-size: 17px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}