.component__statusesModal {
    width: 100%;
    height: 100vh;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.466);

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 400px;
        background: #263565;
        min-height: 600px;
        max-height: 600px;
        border-radius: 5px;

        @media screen and (max-width: 1100px){
            width: 95%
        }

        &__header {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            background: #192343;
            border-radius: 5px 5px 0 0;

            &>h1 {
                font-family: Figtree;
                font-size: 18px;
                color: #ffffff;
                font-weight: 400;
            }

            &>img {
                cursor: pointer;
                width: 24px;
                height: 24px;
            }
        }

        &__body {
            padding: 10px;
            width: 100%;

            &__newStatus {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;
                border: 2px solid #192343;
                padding: 5px;

                &__field {
                    width: 100%;

                    &>p {
                        font-family: Figtree;
                        font-size: 14px;
                        margin-bottom: 2px;
                        color: #ffffff;
                    }

                    &>input {
                        width: 100%;
                        height: 25px;
                        border: none;
                        outline: none;
                        padding-left: 5px;
                        background: #192343;
                        color: #ffffff;
                    }

                    &>input[type='checkbox']{
                       width: 22px;
                       border: none;
                       outline: none; 
                    }
                }

                &__action {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    &>p {
                        color: #263565;
                    }

                    &__button {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        height: 25px;
                        background: #192343;
                        cursor: pointer;
                    }
                }
            }

            &__list {
                margin-top: 20px;
                height: 300px;
                max-height: 300px;
                overflow-y: scroll;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                width: 100%;

                &>h1 {
                    font-size: 16px;
                    font-weight: 400;
                    border-bottom: 2px solid #192343;
                    width: 100%;
                    padding-bottom: 5px;
                    margin-bottom: 10px;
                }

                &__status {
                    display: grid;
                    grid-template-columns: 0.3fr 3fr 0.5fr 0.5fr 0.3fr 0.3fr;
                    background: #192343;
                    padding: 3px;
                    width: 100%;

                    &>p {
                        font-size: 14px;
                        font-family: Figtree;
                    }

                    &>img {
                        margin-left: auto;
                        margin-right: 0;
                        display: block;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}