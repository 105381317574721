.route__login{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #080B16;
    font-family: Figtree;
}

.route__login__wrapper{
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #11182F;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px #483FD7;
}

.route__login__wrapper input{
    width: 100%;
    height: 35px;
    outline: none;
    padding-left: 10px;
    border: none;
    font-size: 16px;
    font-family: Figtree;
    background: transparent;
    border-bottom: 2px solid #483FD7;
    color: #FFFFFF;
    border-radius: 2px;
}

.route__login__wrapper div{
    background: #483FD7;
    width: 100%;
    height: 35px;
    display: flex;
    border-radius: 2px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 20px;
}

.route__login__wrapper > h1{
    font-size: 20px;
    color: #FFFFFF;
    font-family: Figtree;
}

.route__login__wrapper > label{
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 3px;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 600;
    color: #FFFFFF;
    font-family: Figtree;
}