@import "../../scss/mixins.scss";

@keyframes UsersTableMaxHeight {
    from {
        max-height: 0;
    }

    to {
        max-height: 300px;
    }
}

@keyframes UsersTableLineAnim {
    from {
        opacity: 0;
        margin: 0;
    }

    to {
        opacity: 1;
        margin: 15px 0;
    }
}

.route__users {
    width: 100%;
    padding: 20px;
    height: 100vh;

    &__head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__right {
            display: flex;
            justify-content: center;
            align-items: center;

            &__btnCreate {
                background: #192343;
                height: 31px;
                background: #192343;
                padding: 4px;
                border-radius: 5px;
                width: fit-content;
                color: #FFFFFF;
                border: 1px solid #263565;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 180px;
                cursor: pointer;
    
                @media screen and (max-width: 1100px) {
                    width: 100%;
    
                    &>p {
                        font-size: 14px !important;
                    }
                }
            }

            &__btn {
                width: 20px;
                height: 20px;
                cursor: pointer;
                max-width: 0;
                pointer-events: none;
                transition: max-width 0.3s ease, margin 0.3s ease;
                @include button-hover-animation(26px);
                filter: invert(1);

                &--active {
                    margin-right: 10px;
                    max-width: 20px;
                    pointer-events: all;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            &__search,
            &__order {
                width: 200px;
                height: 40px;
                position: relative;
                background: #FFFFFF;
                max-width: 0;
                margin-right: 10px;
                overflow: hidden;
                transition: max-width 0.3s ease, margin-right 0.3s ease;

                &--active {
                    max-width: 300px;
                    margin-right: 10px;
                }

                &>input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding-left: 34px;
                    padding-right: 34px;
                    border-radius: 5px;
                    border: 0.5px solid #A0AEC0;
                    outline: none;
                }

                &__icon1 {
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    top: 50%;
                    left: 12px;
                    transform: translateY(-50%);
                    z-index: 2;
                }

                &__close {
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    top: 50%;
                    right: 12px;
                    z-index: 2;
                    transform: translateY(-50%);
                    background-size: 100% 100%;

                    @include button-hover-animation(20px, "");
                    cursor: pointer;
                }
            }

            &__order {
                margin-right: 0;

                &--active {
                    overflow: visible;
                }
            }

            & .component__simpleDropdown {
                height: 40px;

                &__head {
                    height: 40px;
                    overflow: hidden;
                }
            }
        }
    }

    &__table {
        margin-top: 40px;
        width: 100%;
        overflow: auto;
        display: grid;
        grid-template-columns: repeat(7, minmax(max-content, 100%));
        grid-template-rows: auto;
        column-gap: 30px;
        align-content: flex-start;
        background: #11182F;
        padding: 20px;
        border-radius: 5px;
        overflow-y: scroll;
        max-height: 80vh;
        height: 80vh;

        @media screen and (max-width: 585px){
            max-width: 92vw;
        }

        &>* {
            max-height: 0;

            animation: UsersTableMaxHeight 0.3s ease forwards;
        }

        &__line {
            grid-column: 1 / span 7;
            width: 100%;
            height: 1px;
            border: 1px solid rgba(141, 138, 138, 0.5960784314);
            border-radius: 5px;
            margin: 0;
            opacity: 0;
            animation: UsersTableLineAnim 0.3s ease forwards;
        }

        &__spinner,
        &__error {
            grid-column: 1 / span 7;
        }

        &__error {
            font-family: 'Figtree';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #982a2a;
        }

        &__head {
            font-family: 'Figtree';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            text-transform: uppercase;
            color: #ffffff;
        }

        &__text {
            font-family: 'Figtree';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 140%;
            color: rgb(244, 244, 244);
            display: flex;
            align-items: center;
        }

        &__icon {
            width: 28px;
            height: 28px;
            background-size: 20px 20px;
            background-position: center center;
            background-repeat: no-repeat;
            @include button-hover-animation(28px);
            cursor: pointer;

            &.cloned-element {
                background-image: none !important;
            }
        }

        &__role {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            max-width: 300px;
            flex-wrap: wrap;
            column-gap: 10px;
            row-gap: 5px;
            cursor: pointer;
            transition: filter 0.3s ease;

            &>span {
                font-family: 'Figtree';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 140%;
                padding: 4px 8px;
            }
            &:hover {
                filter: brightness(90%);
            }
        }
    }

    &__add {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.3);
        cursor: default;

        &__wrap {
            width: 100%;
            max-width: 750px;
            height: auto;
            display: grid;
            grid-template-rows: 62px max-content;
            grid-template-columns: 1fr;
            background: #11182F;
            border-radius: 5px;
            border: 1px solid rgb(53, 43, 201);
            padding: 20px;

            &__top {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__head {
                    font-family: 'Figtree';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 140%;
                    color: #ffffff;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                }

                &__btn {
                    width: 24px;
                    height: 24px;
                    background-size: 100% 100%;
                    cursor: pointer;

                    @include button-hover-animation(30px);
                }
            }

            &__bottom {
                padding: 20px;
                display: grid;
                grid-template-rows: max-content;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                background: #101527;
                border-radius: 5px;
                border: 1px solid rgb(53, 43, 201);

                &__input {
                    color: #ffffff;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;
                    flex-direction: column;

                    &>input {
                        width: 100%;
                        background: #FFFFFF;
                        outline: none;
                        border: none;
                        font-family: 'Figtree';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 17px;
                        color: #ffffff;
                        background: transparent;
                        border-bottom: 2px solid rgb(53, 43, 201);
                        height: 30px;

                        &::placeholder{
                            color: #ffffff;
                        }
                    }

                    &--error>input {
                        box-shadow: 0px 0px 4px rgba(255, 0, 0, 0.8);
                    }

                    &--disabled>input {
                        pointer-events: none;
                        cursor: not-allowed;
                        background: rgb(53, 43, 201);
                    }

                    &>label {
                        font-family: 'Figtree';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        color: #ffffff;
                    }

                    & .component__customComponent__dropbox {
                        height: 50px;
                        width: 100%;
                        border: none;
                        box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(0, 0, 0, 0.2) 0px 0px 4px !important;
                        transition: filter 0.3s ease;

                        .component__customComponent__dropbox__text,
                        .component__customComponent__dropbox__dropdown__data {
                            color: #13213C;
                            font-weight: normal;
                        }
                    }

                    &--error .component__customComponent__dropbox {
                        box-shadow: 0px 0px 4px rgba(255, 0, 0, 0.8) !important;
                    }
                }

                &__roles {
                    grid-column: 1 / span 2;

                    &>label {
                        font-family: 'Figtree';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 22px;
                        color: #ffffff;
                    }

                    &__list {
                        margin-top: 10px;
                        width: 100%;
                        height: auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-wrap: wrap;

                        gap: 20px;

                        &__item {
                            padding: 4px 6px;
                            font-family: 'Figtree';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 140%;
                            cursor: pointer;
                            color: #ffffff;
                            border: 1px solid transparent;
                            border-radius: 3px;

                            transition: color 0.3s ease, backgroundColor 0.3s ease, border 0.3s ease;
                        }
                    }
                }

                &__btns {
                    width: 100%;
                    height: auto;
                    grid-column: 1 / span 2;
                    margin-top: 30px;
                    display: grid;
                    grid-template-rows: max-content;
                    grid-template-columns: 1fr max-content;
                    align-items: center;

                    &__btn {
                        background: rgb(53, 43, 201);
                        cursor: pointer;
                        width: fit-content;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        border-radius: 5px;
                        color: #ffffff;
                        border: 2px solid rgb(42, 34, 153);
                        transition: 400ms;
                        padding-left: 10px;
                        padding-right: 10px;
    
                        &:hover {
                            box-shadow: 0 0 8px rgb(42, 34, 153);
                        }
    
                        &>p {
                            font-size: 15px;
                            font-family: Figtree;
                        }
    
                        margin-left: auto;
                        margin-right: 0;
                    }

                    &__infoP {
                        margin-top: 20px;
                        font-family: 'Figtree';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 140%;
                        letter-spacing: 0.05em;
                        color: #FF0000;
                        opacity: 0;
                        transition: opacity 0.3s ease;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .route__users__add__wrap{
        max-width: 95% !important;
        max-height: 550px !important;
        overflow-y: scroll;
    }
}

@media screen and (max-width: 500px){

    .route__users{
        padding: 10px;
    }

    .route__users__add__wrap__bottom{
        display: flex !important;
        flex-direction: column !important;
    }

    .route__users__head__right__btnCreate{
        padding: 5px !important;
        column-gap: 5px !important;
    }

    .route__users__head{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 10px;
        height: fit-content;
        justify-content: flex-start;
    }

    .route__users__head__left{
        justify-content: flex-start;
    }

    .route__users__head__right{
        justify-content: flex-end;
    }

    .route__users__table{
        max-height: 90vh;
        min-height: 90vh;
    }

    .route__users__head__right .component__simpleDropdown__head, .component__simpleDropdown{
        width: 100px !important;
    }

    .route__users__head__right__search--active, .route__users__head__right__order--active{
        max-width: 150px;
    }

    .route__users__head__right__btnCreate{
        font-size: 14px;
    }
}